import StripePay from "../../components/StripePay";
import Footer from "../../components/Footer";

const Pay = () => {
  return (
    <div>
      <StripePay />
      <Footer />
    </div>

  );
}

export default Pay;