import React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';

const Footer = props => {
  return (
    <footer className="footer">
       
      <div className="wrap">
          <text> 
          © 2021 LOT.™. LOT.™ is a trademark owned by LOTIX™ and Pay App® is owned by PlayPay™. Other trademarks herein are owned by their respective holders. All rights reserved. 
          </text> 
          <li>
            <Link to="/TermsOfUse">
              Terms & Conditions| 
            </Link> 
            <Link to="/TermsOfUse">
              About Pay App®|
            </Link> 
            <Link to="/TermsOfUse">
              Privacy Policy|
            </Link> 
            <Link to="/TermsOfUse">
              Cookies Policy
            </Link> 
          </li>
      </div>
    </footer>
  );
}

export default Footer; 