import React from "react";
import $ from "jquery"



export default class FetchRandomUser extends React.Component {
  state = {
    loading: true,
    result: null,
  };

    async componentDidMount() {
        //var response = '';
        const url = "http://playpay.dev.catalogapi.com/v1/rest/catalog_breakdown";
        const response = await $.getJSON(url,  
                {
                token: "b6ab2dc359b7b68ebb37762bac12395c10b9862c",
                socket_id: '1272',
                //category_id: '2648'
           
            
                
                },)
        const data = await response;
        console.log(data)//.catalog_breakdown_response.catalog_breakdown_result.categories.Category)
        //this.setState({result: data.catalog_breakdown_response.catalog_breakdown_result.categories.Category, loading:false })
        
    }

    
  render() {
    if (this.state.loading) {
        return <div>loading...</div>;
      }
  
      if (!this.state.result) {
        return <div>didn't get a result</div>;
      }
  
        return (
            <div>
            {this.state.result.name}
          </div>
        );
      }
    }
   
      


    
//   async componentDidMount() {
//     const url = "http://playpay.dev.catalogapi.com/v1/rest/list_available_catalogs/";
//     const token = '';
//     const response = await fetch(url, { 
        
//         method: 'get', 
//         headers:{
//             mode: 'no-cors',
//             method: "post",
//             headers: {
//                 "Access-Control-Allow-Origin": "https://playpay.dev.catalogapi.com/",
//                  "Authorization": `token ${token}`
//             },
//           // Your header content
//         }
//       });
    
//     const data = await response.json();

//     console.log(data)
//   }

//   render() {
  
//     return(
//         <div>
//             {this.state.loading ? <div>loading...</div> : <div>item</div>}
//         </div>
//     );
//   }
// }
