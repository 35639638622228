import React, { Component } from 'react';
import './styles.scss';
import Footer from './../../components/Footer';

class TermsOfUse extends Component {
  render() {
    return (
     <div className= "textBlock">
      
      <ol>
      <h1>Policy & Agreement</h1>      
       <h2>Index</h2>
       <li>
       <a href = "#aboutUs" > About us </a>
       </li>
       <li>
       <a href = "#use" > Use of this site </a>
       </li>
       <li>
       <a href = "#placing" > Placing an order</a>
       </li>
       <li>
       <a href = "#products" > Products</a>
       </li>
       <li>
       <a href = "#claims" > Reporting claims of infringement of intellectual property rights</a>
       </li>
       <li>
       <a href = "#content" > Content on the Site</a>
       </li>

       </ol>



      
       <h2 id = "aboutUs">About us</h2>
       <p> 
       We, along with our affiliates and subsidiaries, are LOT.™ You can contact us by email at support@lotix.com.
       </p>

       <h2 id = "use">Use of the site</h2>
       <p> 
       When you use the Site or our services, you agree to use the Site and our services only for purposes that are permitted by these Terms and any applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdictions.  In addition, you agree that you are solely responsible for any breach of your obligations under these Terms and for the consequences (including any loss or damage which LOT.™ may suffer) of any such breach. As a condition of your use of the Site and our services, you warrant to LOT.™ that you will not use the Site or our services for any purpose that is unlawful or prohibited by these Terms. Whether on behalf of yourself or on behalf of any third-party, you agree not to:

-Access or attempt to access the Site other than through the interface that is provided by LOT.™, unless you have specifically been permitted to do so in a separate written agreement signed by an authorized representative of LOT.™;

-Engage in any activity that disrupts or otherwise interferes with the Site (or the servers and networks which are connected to the Site), or our services;
“Scrape”, duplicate, reproduce, copy, republish, license, sell, trade or resell the Site for any purpose;

-Divert or attempt to divert LOT.™ customers to another website, mobile application, or service;

-Send unsolicited or unauthorized emails on behalf of LOT.™, including promotions and/or advertising of products or services;

-Restrict or inhibit any other person from using the Site or our services;
Modify, adapt, translate, reverse engineer, decompile, or disassemble any portion of the Site;

-Attempt to do anything, or encourage, assist, or allow any third-party to do anything in violation of these Terms; or

-Use a false email address, impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with any person or entity in connection with the Site or our services.

LOT.™ reserves the right to refuse service, in its sole discretion, at any time, for any lawful reason, without notice. LOT.™ reserves the right to withdraw or amend the Site, and any service or material we provide on the Site, in its sole discretion, at any time, for any reason, without notice. LOT.™ WILL NOT BE LIABLE IF FOR ANY REASON ALL OR ANY PART OF THE SITE IS UNAVAILABLE AT ANY TIME OR FOR ANY PERIOD. FROM TIME TO TIME, WE MAY RESTRICT ACCESS TO SOME PARTS OF THE SITE, OR THE SITE ENTIRELY, TO USERS, INCLUDING REGISTERED USERS.
       </p>

       <h2 id = "placing">Placing an order</h2>
       <p> 
       Your order is an offer to LOT.™ to buy the product(s) and services listed in your order along with a sales order number. When you place an order to purchase a product and/or service from LOT.™, we will send you an email confirming receipt of your order and containing the details of your order (the “Order Confirmation”). Your receipt of the Order Confirmation only confirms that we have received your order; it does not constitute LOT.™’s acceptance of your order or LOT.™’s confirmation of our offer to sell. An order is not binding upon LOT.™ until it is accepted; LOT.™ must receive payment before it will accept an order. Payment for product(s) ordered is due prior to shipment. Once payment is received and an order is accepted, LOT.™ will issue an invoice to you and a contract will come into existence between you and us. LOT.™ may, in its sole discretion, at any time after receiving your order, accept or decline your order for any reason. In addition, LOT.™ may, in its sole discretion, cancel your order and limit order quantity. Where your order is declined, cancelled or limited, we will inform you of this by appropriate means and you will not be charged. If however, your order is limited or canceled after your credit card has been charged for the purchase, LOT.™ will issue a credit to your credit card account in the amount of the charge.

We may process payment for and ship parts of your order separately.

You consent to receive sales invoices electronically. Electronic invoices will be made available in pdf format in the “your account” area of the Site.

MARKETPLACE ORDERS

Marketplace: For an order for an item that is listed on the Site as “Sold and Shipped by” a third-party seller through LOT.™’s Marketplace platform (“Marketplace Seller”) and not “Sold and Shipped by LOT.™” (“Marketplace Item”), LOT.™ is the seller of record and the order processing is as set out at section “3.1.1. Orders” above and the contract is formed between you and LOT.™.

However, although LOT.™ is the seller or record, the Marketplace Seller and not LOT.™ is solely responsible for fulfilling and delivering the order to you and bears all liability and risks associated with, or related to, any item that is sold and shipped by that Marketplace Seller that is purchased by a customer through the Site. When you place an order for a Marketplace Item, we will provide the Marketplace Seller with the information necessary to process and fulfill the order, in accordance with the terms of LOT.™’s Privacy Policy. Please note that Marketplace Sellers may have policies that differ from LOT.™’s policies; therefore, for orders for Marketplace Items, please review the applicable Marketplace Seller’s policies for any other terms and conditions that may apply to your order for such items. The Marketplace Seller’s posted policies will control to the extent of any inconsistency between LOT.™’s policies and the Marketplace Seller’s posted policies.

3.1.2. Pre-orders

Click the Pre-order button in the item description and checkout as you normally would. A sales order number will be issued at the Order Confirmation screen just as if you were placing a conventional order, but your credit/debit card will not be charged until your pre-ordered item is in stock and ready to ship. If you purchase an in-stock item with a pre-order item, you will be charged accordingly for the available product and the difference when your pre-ordered item is in stock and ready for shipment. In this instance, two sales order numbers will be issued; one for the in-stock merchandise and one for the pre-ordered merchandise. You will be kept up-to-date via the Site and through standard Order Status emails to your account.

3.1.3. Backorders

We will sometimes allow you to backorder an item that is out of stock. However, these items will be clearly marked with a Backorder button. Click the Backorder button in the item description and checkout as you normally would. A sales order number will be issued at the Order Confirmation screen just as if you were placing a conventional order, but your credit/debit card will not be charged until your backordered item is in stock and ready to ship. If you purchase an in-stock item with a backorder item, you will be charged accordingly for the available product and the difference when your backordered item is in stock and ready for shipment. In this instance, two sales order numbers will be issued; one for the in-stock merchandise and one for the backordered merchandise. You will be kept up-to-date via the Site and through standard Order Status emails to your account.

Marketplace: Backordering is not currently available for Marketplace Items.

3.2. Payment

In order to place an order with LOT.™, you must provide us with your name, shipping address, billing address, telephone number, and payment information, such as your credit card number, billing address, credit card expiration date, and credit card security code. You represent and warrant to LOT.™ that the credit card information or other payment method information you provide to LOT.™ is accurate and complete; and you are authorized to use such credit card or payment method for the purchase.

Terms of payment are within our sole discretion and we must receive payment before our acceptance of an order. Payment methods may vary by location. To learn about U.S. payment methods, please click here. Depending on your location, we may accept any of the following payment methods:

Visa
MasterCard
American Express
Discover
PayPal
LOT.™ Gift Card
MasterPass
Amex Express Checkout
Bitcoin
Android Pay
Apple Pay
3.3. Shipping

Your total cost for the purchase of any product will include the shipping and handling charges shown on your LOT.™ invoice. Your original shipping fees are non-refundable in the case of a returned product. Those fees are paid for the services provided by and to the original carrier, and will not be refunded to you if your item is returned for a refund to LOT.™.

To learn more about shipping for U.S. customers, please review our U.S. Shipping Policy. The Domestic Shipping Policy is incorporated into these Terms in its entirety.

3.4. Risk of Loss and passing of Title

LOT.™ will arrange for shipment of ordered product(s) to you, Free Carrier (F.C.A.) risk of loss passes to you upon delivery to the carrier. Title to the product(s) – excepting software – will also pass to you upon delivery to the carrier. Title to software will remain with the licensor(s). All software is provided subject to the license agreement of the software maker. You agree to be bound by any software license agreement once the seal on the package is broken. LOT.™ will advise you of estimated shipping dates, but LOT.™ will, under no circumstances, be responsible for delays in delivery, and associated damages, due to events beyond its reasonable control, including without limitation, acts of God or public enemy, acts of federal, state or local government, fire, floods, civil disobedience, strikes, lockouts, and freight embargoes.

3.3. Taxes

Under applicable state laws, sales tax will be charged for orders shipping into states where LOT.™ is required to collect tax. Therefore, all orders shipped will be charged applicable sales tax according to your area’s tax rate except for the following states:

Alaska, excluding the municipalities list below;
The City of Aleknagik
The City of North Pole
The City and Borough of Juneau
The City of Wasilla
The Kenai Peninsula Borough
The City of Kenai
The City of Seldovia
The City of Homer
The City of Soldotna
The City of Seward
The City and Borough of Wrangell
The City of Nome
The Haines Borough
The City of Adak
The City of Palmer
The City of Cordova
The Petersburg Borough
The City of Gustavus
The City of Kodiak
The City of Unalaska
The City and Borough of Sitka
The City of Dillingham
The City and Borough of Yakutat
The City of Craig
The City of Tenakee Springs
The City of Mountain Village
The City of Saint Paul
The City of Togiak
The City of Toksook Bay
The Ketchikan Gateway Borough
The City of Thorne Bay
The City of Bethel
The City of Houston
The City of Ketchikan
The City of Saxman
Delaware;
Florida;
Kansas;
Missouri;
Montana;
New Hampshire; and
Oregon.
Please note that even if LOT.™ does not collect state sales tax, you are required to pay it when you file your state taxes.

Sales tax laws continuously change, and we will use commercially reasonable efforts to keep this list current.

3.6. Tax exemption

If you are a tax-exempt organization or reseller, you can apply for tax exemption on your LOT.™ orders. To learn about tax exemptions, please click here.

3.7. Customs, duties & taxes

When ordering products from LOT.™ for delivery from outside of your jurisdiction, you may be subject to import duties and taxes, which are levied once the package reaches the specified destination. Any additional charges for customs clearance must be borne by you; we have no control over these charges. Contact your local customs office for further information. Additionally, please note that when ordering from LOT.™, you are considered the importer of record and must comply with all laws and regulations of the country in which you are receiving the products. Your privacy is important to us and we would like our customers to be aware that cross-border deliveries are subject to opening and inspection by customs authorities.

3.8. Order processing

3.8.1. Order status

LOT.™ will keep you informed of your order status via email. All tracking information will be emailed to your LOT.™ ID once your order has shipped (except when an APO/FPO/DPO is the shipping destination). You may also acquire your order status and other live updates by logging in to your account on the Site.

3.8.2. Order processing

Once you have placed your order, you can expect your order to be processed within approximately 1-2 business days, provided the items are in stock and there are no problems with payment verification. LOT.™ does not guarantee same day-shipping. We do not process orders on Saturdays or U.S. holidays.

Marketplace: If you purchase a Marketplace Item from a Marketplace Seller, that Marketplace Seller is responsible for shipping the item to you. While LOT.™ does not guarantee processing times for Marketplace Items, in general Marketplace Sellers should process orders within 1-2 business days of order verification.

3.8.3. Rush processing

Rush Processing expedites the fulfillment of your order so it can be packed, shipped and on the road the same business day it is placed. For Rush Processing to be effective, your order must be submitted and charged before 2:30 p.m. PST, Monday through Friday. If the above conditions are met and we fail to ship your order the same business day, we will refund the Rush Processing fee.

The following items and order types are not eligible for rush processing Marketplace Items, third-party LOT.™ distribution centers, Backorders, Pre-orders, DVD movies, Large Item Delivery shipping method, ShopRunner shipping service, Super Eggsaver and Will Call orders. For orders with eligible and ineligible items, rush processing will apply to the eligible items only. Ineligible items will automatically be split onto a separate order.

For orders placed between 11 a.m. and 1 p.m. PST, Monday through Friday, the Rush Processing fee is $3.99. For all other times, the Rush Processing fee is $2.99.

IN ALL CIRCUMSTANCES, LOT.™‘S MAXIMUM LIABILITY IS LIMITED TO THIS RUSH ORDER FEE ONLY. WHILE LOT.™ CANNOT GUARANTEE SAME DAY SHIPMENT FOR ALL ORDERS, LOT.™ WILL PUT FORTH COMMERCIALLY REASONABLE EFFORTS TO SHIP ALL RUSH PROCESSING ORDERS THE SAME BUSINESS DAY IF THE ABOVE CONDITIONS ARE MET. OTHERWISE, YOUR RUSH PROCESSING FEE WILL BE REFUNDED.

Marketplace: Rush processing is not currently available for Marketplace Items.

3.9. Canceling orders

You may cancel your order for a product at no cost any time before the order has been processed and shipped, provided LOT.™ has sufficient time to cancel the order.

Nothing in this section limits your rights to cancel your order under any law.

3.10. Returns

To learn about returns, please review our Return Policy.  The Return Policy is incorporated into these Terms in its entirety.

Nothing in the Return Policy limits your rights and remedies under any law.

       </p>

       <h2 id = "products">Products</h2>
       <p> 
       4.1 Products

Unless expressly indicated otherwise, LOT.™  is not the manufacturer of the products sold on the Site. Actual product packaging and materials may contain more and different information to that displayed on the Site. Ingredients may also change. All information about the products on the Site is provided for information purposes only, and LOT.™  MAKES NO WARRANTIES OR REPRESENTATIONS AS TO THE SITE’S ACCURACY. We recommend that you do not rely solely on the information presented on the Site. Please always read labels, warnings and directions provided with the product before use.

For healthcare products, in the event of any safety concerns or for any other information about a product, please carefully read the information provided with the product or contact the manufacturer. Content on the Site is not intended to substitute for advice given by a medical practitioner, pharmacist or other licensed healthcare professional. Contact your healthcare provider immediately if you suspect that you have a medical problem. Information and statements about products are not intended to be used to diagnose, treat, cure or prevent any disease or health condition.

TO THE FULLEST EXTENT PERMITTED BY LAW, LOT.™  ACCEPTS NO LIABILITY AND RESPONSIBILITY FOR INACCURACIES OR MISSTATEMENTS ABOUT PRODUCTS BY MANUFACTURERS OR OTHER THIRD PARTIES.

Notwithstanding the foregoing, nothing in this section limits your rights and remedies under any law.

4.2. Product testing

LOT.™ , like other e-retailers, ships items in the condition they were received from the original manufacturer/distributor. LOT.™  does not conduct product testing before shipping any merchandise.

Marketplace: Marketplace Sellers may or may not conduct product testing. Check the Marketplace Seller’s Policy Page for information on the product testing practices of individual Marketplace Sellers.

4.3. Technical support

LOT.™  does not offer any sort of technical assistance. We cannot offer advice on compatibility of items, make product recommendations, or provide technical support or sales advice. We post all of the information the manufacturer provides us with for your convenience. Please contact the manufacturer directly for accurate answers to your technical questions or for additional information or specifications that are not posted at LOT.™ .

Marketplace: If you need technical support fora Marketplace Item, please direct your requests for technical assistance to the applicable Marketplace Seller. However, please be aware that certain Marketplace Sellers may not offer technical assistance. Check the Marketplace Seller’s Policy Page of the individual Marketplace Seller for details.

4.4. Product-specific policies

4.4.1 “Must Be Purchased with Hardware”

The “must be purchased with hardware” precept is mandated by the software publisher. In accordance, LOT.™  customers must purchase some type of product that falls under the general category of computer hardware in order to purchase applicable software. Hardware is classified as any component that will be used when assembling a computer system (a hard drive, motherboard, processor and a floppy drive are all examples of merchandise that will qualify). There is no specific or set price the hardware has to be, merely that there is some sort of eligible hardware present in the order itself.

4.4.2 Buyers of optional service plans, warranties, and protection plans

If you purchase certain products through the Site, you may be able to purchase an optional service plan, warranty, or protection plan (the “Plans”) for the product. The Plans are provided by third-party service providers and may have their own set of terms and conditions. If applicable, the details of the Plans will be available to you at the point of purchase. You should carefully review the applicable terms and conditions for the Plans before you purchase any of the Plans.

You authorize LOT.™  to initiate a charge to the credit card or other payment method that was used to purchase the equipment in the amount of $250.00, the equipment discount, if it is due.

ithout notice. LOT.™ reserves the right to withdraw or amend the Site, and any service or material we provide on the Site, in its sole discretion, at any time, for any reason, without notice. LOT.™ WILL NOT BE LIABLE IF FOR ANY REASON ALL OR ANY PART OF THE SITE IS UNAVAILABLE AT ANY TIME OR FOR ANY PERIOD. FROM TIME TO TIME, WE MAY RESTRICT ACCESS TO SOME PARTS OF THE SITE, OR THE SITE ENTIRELY, TO USERS, INCLUDING REGISTERED USERS.
       </p>

       <h2 id = "claims">Reporting claims of infringement of intellectual property rights</h2>
       <p> 
       5.1. Notices of copyright infringement

We will respond to notices of alleged copyright infringement that comply with applicable law. If you believe any materials accessible on or from LOT.™  infringe your copyright, you may request removal of those materials (or access to them) from LOT.™  by submitting written notification to our Copyright Agent (designated below). In accordance with the Online Copyright Infringement Liability Limitation Act of the Digital Millennium Copyright Act (17 U.S.C. § 512) (“DMCA”), the written notice (the “DMCA Notice”) must include substantially the following:

A physical or electronic signature of the copyright owner or a person who is authorized to act on behalf of the copyright owner;
Identification of the copyrighted work you believe to have been infringed;
Identification of the material you believe to be infringing in a sufficiently precise manner to allow us to locate that material;
Your name, mailing address, telephone number, and email address;
A statement by you that you have a good faith belief that use of the copyrighted material is not authorized by the copyright owner, its agent, or the law; and
A statement by you that the information is accurate, and under penalty of perjury, that you are authorized to act on behalf of the copyright owner.

If you fail to comply with all of the requirements of Section 512(c)(3) of the DMCA, your DMCA Notice may not be effective.

Please be aware that if you knowingly materially misrepresent that material or activity on LOT.™  is infringing your copyright, you may be held liable for damages (including costs and attorneys’ fees) under Section 512(f) of the DMCA.

Counter-notification procedures

If you believe that material you posted on the Site was removed or access to it was disabled by mistake or misidentification, you may file a counter-notification with us (a “Counter-Notice”) by submitting written notification to our copyright agent (identified below). Pursuant to the DMCA, the Counter-Notice must include substantially the following:

Your physical or electronic signature;
Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access disabled;
Your name, mailing address, telephone number, and email address;
A statement under penalty of perjury by you that you have a good faith belief that the material identified above was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled; and
A statement that you will consent to the jurisdiction of the Federal District Court for the judicial district in which your address is located (or if you reside outside the United States for any judicial district in which LOT.™  may be found) and that you will accept service from the person (or an agent of that person) who provided LOT.™  with the complaint at issue.

Counter-notification procedures

If you believe that material you posted on the Site was removed or access to it was disabled by mistake or misidentification, you may file a counter-notification with us (a “Counter-Notice”) by submitting written notification to our copyright agent (identified below). Pursuant to the DMCA, the Counter-Notice must include substantially the following:

Your physical or electronic signature;
Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access disabled;
Your name, mailing address, telephone number, and email address;
A statement under penalty of perjury by you that you have a good faith belief that the material identified above was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled; and
A statement that you will consent to the jurisdiction of the Federal District Court for the judicial district in which your address is located (or if you reside outside the United States for any judicial district in which LOT.™  may be found) and that you will accept service from the person (or an agent of that person) who provided LOT.™  with the complaint at issue.

The DMCA allows us to restore the removed content if the party filing the original DMCA Notice does not file a court action against you within ten business days of receiving the copy of your Counter-Notice.

Please be aware that if you knowingly materially misrepresent that material or activity on the Site was removed or disabled by mistake or misidentification, you may be held liable for damages (including costs and attorneys’ fees) under Section 512(f) of the DMCA.

5.2. Notices of trademark Infringement

In order to remove trademarks, service marks, and/or trade dress infringing content, please send a notice of trademark infringement containing the following information to LOT.™ :

A physical or electronic signature of the trademark owner or a person who is authorized to act on behalf of the trademark owner;
Identification of the trademark, service mark, and/or trade dress you believe to have been infringed;
Identification of the material you believe to be infringing in a sufficiently precise manner to allow us to locate that material;
Your name, mailing address, telephone number, and email address;
A statement by you that you have a good faith belief that the use of the material is not authorized by the intellectual property owner, its agent, or the law; and
A statement by you that the information is accurate, and under penalty of perjury, that you are authorized to act on behalf of the intellectual property owner.

5.3. Notices of Infringement of other intellectual property rights

In order to remove infringing content, please send a notice of infringement containing the following information to LOT.™ :

A physical or electronic signature of the owner of the other intellectual property right or a person who is authorized to act on behalf of the owner of the intellectual property right;
Identification of the other intellectual property right you believe to have been infringed;
Identification of the material you believe to be infringing in a sufficiently precise manner to allow us to locate that material;
Your name, mailing address, telephone number, and email address;
A statement by you that you have a good faith belief that the use of the material is not authorized by the intellectual property owner, its agent, or the law; and
A statement by you that the information is accurate, and under penalty of perjury, that you are authorized to act on behalf of the intellectual property owner.

</p>


       <h2 id = "content">Content on the site</h2>
       <p> 
       6. Content on the Site
6.1. Intellectual property

All content included on the Site, such as text, graphics, logos, images, videos, digital downloads, data, software, any other material, and the design selection and arrangement thereof is owned or licensed property of LOT.™, its suppliers, licensors, or other providers of such material and is protected United States and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws. LOT.™ and its suppliers and licensors expressly reserve all intellectual property rights in all content.

These Terms permit you to use the Site for your personal, non-commercial use only.

The LOT.™ name, logos, service names, designs, and slogans are trademarks of LOT.™. You must not use such marks without the prior written permission of LOT.™. All other names, logos, product and service names, designs and slogans that appear on the Site are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by LOT.™.

6.2. Pricing and availability

All prices are displayed in the currency of the jurisdiction of the country you have selected when accessing the Site.

All pricing is subject to change without notice, and LOT.™ reserves the right to make adjustments due to changing market conditions, product discontinuation, manufacturer price changes and other extenuating circumstances.

On rare occasions, an item in our catalogue may be mispriced. We will verify pricing when processing your order and before we take payment. IF WE HAVE MADE A MISTAKE AND A PRODUCT’S CORRECT PRICE IS HIGHER THAN THE PRICE ON THE SITE, WE MAY EITHER CONTACT YOU BEFORE SHIPPING TO ASK WHETHER YOU WANT TO BUY THE PRODUCT AT THE CORRECT PRICE OR CANCEL YOUR ORDER. If a product’s correct price is lower than our stated price, we will charge the lower amount and send you the product.

LOT.™ may limit the number of products available for purchase. LOT.™ may, in its sole discretion, at any time, for any reason, change the quantities available for purchase.

We list availability information for products sold by us on the Site, including on each product information page. Beyond what we say on that page or otherwise on the Site, we cannot be more specific about availability. As we process your order, we will inform you by email as soon as possible if any products you order turns out to be unavailable. LOT.™ shall at its sole discretion, at any time, have the right to refuse or cancel any order for a product that is unavailable. If such order is canceled after your credit card has been charged for the purchase, LOT.™ will issue a credit to your credit card account in the amount of the charge.

6.3. Product listings

Prices and availability of products are subject to change without notice. Any changes to LOT.™-controlled prices, specifications and terms will be effective only after the effective date of the change and will not affect any dispute arising prior to the effective date of the change.  LOT.™ strives for accuracy in all item descriptions, photographs images, compatibility references, detailed specifications, pricing, links and any other product-related information contained herein or referenced on the Site. Due to system, typographical, informational, technical, human, and other error, we cannot and do not guarantee that all information, including descriptions, photographs images, compatibility references, detailed specifications, pricing, links, availability and any other product-related information listed is accurate, complete or current, nor do we assume responsibility for these errors. LOT.™ reserves the right at any time and without notice to update product information and to correct and/or remove product-related errors, inaccuracies, or omissions. To the fullest extent permitted by law, LOT.™ shall at its sole discretion have the right at any time to refuse and/or cancel any order for a product whose listing on the Site contained erroneous information, including but not limited to incorrect pricing. If such order is canceled after your credit card has been charged for the purchase, LOT.™ will issue a credit to your credit card account in the amount of the charge. Additionally, all hyperlinks to other websites from LOT.™ are provided as resources to customers looking for additional information and/or professional opinion. LOT.™ does not assume responsibility for the claims and/or representations made on these or any other websites.

Notwithstanding the foregoing, nothing in this section limits your rights and remedies under any law.

6.4. Product revisions

LOT.™ IS NOT RESPONSIBLE FOR CHANGES OR VARIATIONS IN PRODUCT SPECIFICATIONS AND/OR PHYSICAL APPEARANCE. In the interest of our customers, LOT.™ puts forth commercially reasonable efforts to ensure that all product information is up-to-date and factual. Unfortunately there are varying determinates which, although infrequent, could cause the information on the Site to become outdated without our immediate knowledge. This includes but is not limited to new versions or revisions, color deviations, retail package alterations and other variations that may be considered inconsequential by the manufacturer. LOT.™ relies on the manufacturer to communicate these differences. Presently we have no way of alerting customers prior to purchase in the event the manufacturer fails to do so. CONSEQUENTLY, LOT.™ WILL NOT BE HELD RESPONSIBLE FOR PRODUCT REVISION CHANGES.

6.5. Links

All links to other websites from LOT.™ are provided as resources to customers looking for additional information and/or professional opinion. To the fullest extent permitted by law, LOT.™ does not assume responsibility for the claims and/or representations made on these or any other websites. IF YOU ACCESS ANY OF THE THIRD-PARTY WEBSITES LINKED TO THE SITE, YOU DO SO ENTIRELY AT YOUR OWN RISK AND SUBJECT TO THE POLICIES AND TERMS AND CONDITIONS FOR SUCH WEBSITES.

6.6. Product Tour

The product resources offered in Product Tour are provided courtesy of their respective developers. As such, to the fullest extent permitted by law, LOT.™ is not responsible in any way for the accuracy of claims, representations or suggestions made in these materials. Similarly, all compatibility or technical issues should be directed to the appropriate developer or manufacturer. THESE RESOURCES ARE PROVIDED ON AN “AS IS” BASIS BY LOT.™ AS A SUPPLEMENTARY SERVICE TO YOU, THE USER.  We offer these resources to you strictly for informative purposes as they may lend insight into products you are interested in and clarify any associated benefits.


</p>







    </div>
    
    )}
  }
   export default TermsOfUse;