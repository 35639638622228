import React from 'react';
import ShippingAddress from './../../components/ShippingAddress';
import Footer from '../../components/Footer';


const Shipping = ({}) => {
    return (
      <div>
        <div>
          <ShippingAddress />
        </div>
      </div>

  
      
    );
  }

export default Shipping;