import React from 'react';
import DetailedProduct from '../../components/DetailedProduct';
import Footer from './../../components/Footer';

const ProductDetails = ({}) => {
  return (
    <div>
      <div>
        <DetailedProduct />
      </div>
    </div>

    
  );
}

export default ProductDetails;