import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { checkUserSession } from './redux/User/user.actions';


// components
import AdminToolbar from './components/AdminToolbar';
import Footer from './components/Footer';
//import NewProductCard from './components/NewProductCard'
import DetailedProduct from './components/DetailedProduct'
import DetailedProductSurfNote from './components/DetailedProductSurf'
import PayCart from './components/PayCart'
//import CheckoutForm from  './components/StripePay'


// hoc
import WithAuth from './hoc/withAuth';
import WithAdminAuth from './hoc/withAdminAuth';


// layouts
import MainLayout from './layouts/MainLayout';
import HomepageLayout from './layouts/HomepageLayout';
import AdminLayout from './layouts/AdminLayout';
//import DashboardLayout from './layouts/DashboardLayout';
import RegistrationLayout from './layouts/RegistrationLayout';


// pages
import SearchPage from './pages/SearchPage';
import Homepage from './pages/Homepage';
import Search from './pages/Search';
import Registration from './pages/Registration';
import Login from './pages/Login';
import Recovery from './pages/Recovery';
//import Dashboard from './pages/Dashboard';
import Admin from './pages/Admin';
import ProductDetails from './pages/ProductDetails';
//import ProductDetailsSurf from './pages/ProductDetailsSurf';
import Cart from './pages/Cart';
import Payment from './pages/Payment';
//import Order from './pages/Order';
import TermsOfUse from './pages/TermsOfUse';
import './default.scss';
//import Header from './components/Header';
import CartPage from './pages/CartPage';
//import PaymentPro from './pages/PaymentPro';
//import OrdersPage from './pages/OrdersPage';
import Shipping from './pages/ShippingAddress';
import Pay from './pages/Pay';
//import { StripeProvider } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import SurfSearchPage from './pages/SurfSearchPage';
import OrdersSurf from './pages/OrdersSurf';





const promise = loadStripe("pk_live_51J7OBnEXGaqKK094a7A7nIp4vjmFDjdOo3Yl7nvx8h6aJd2oVM2V68bA82dQa3tzLbOEltsYqRoB3ji7W7x7zmfL00t7JmlpJe");

const App = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkUserSession());

  }, []);

  

  return (
    <div className="App">
      <AdminToolbar />
      <Switch>
        <Route exact path="/" render={() => (
          <HomepageLayout>
            <Homepage />
          </HomepageLayout>
        )}
        />

        <Route path="/search/:filterType" render={() => (
          <MainLayout>
            <Search />
          </MainLayout>
        )} />
        <Route path="/product" render={() => (
          <MainLayout>
            <ProductDetails />
          </MainLayout>
        )} />
        <Route path="/cartOld" render={() => (
          <MainLayout>
             <Cart />
          </MainLayout>
             
            
            
            
        )} />
        <Route path="/payment" render={() => (
          <WithAuth>
            <MainLayout>
              <Payment />
            </MainLayout>
          </WithAuth>
        )} />


        <Route path="/pay" render={() => (  
          
           <MainLayout>
             
            <Elements stripe={promise}>
              <Pay />
            </Elements>
          </MainLayout>
          
        )}/>
                 


        <Route path="/registration" render={() => (
          <RegistrationLayout>
            <Registration />
          </RegistrationLayout>
        )} />

        <Route path="/login"
          render={() => (
            <RegistrationLayout>
              <Login />
              </RegistrationLayout>
          )} />
        <Route path="/recovery" render={() => (
          <RegistrationLayout>
            <Recovery />
            </RegistrationLayout>
        )} />
        {/* <Route path="/dashboard" render={() => (
          //<WithAuth>
            <DashboardLayout>
              <Dashboard />
            </DashboardLayout>
          //</WithAuth>
        )} />
        <Route path="/order/:orderID" render={() => (
          //<WithAuth>
            <DashboardLayout>
              <Order />
            </DashboardLayout>
          //</WithAuth>
        )} /> */}
        <Route path="/admin" render={() => (
          <WithAdminAuth>
            <AdminLayout>
              <Admin />
            </AdminLayout>
          </WithAdminAuth>
        )} />


        <Route exact path="/search" render={() => (
        <WithAuth>
        <HomepageLayout>
          
          <SearchPage />
        </HomepageLayout>
        </WithAuth>
  
              
        )} />

       <Route exact path="/searchSurf" render={() => (
       
       <HomepageLayout>
          <SurfSearchPage />
        </HomepageLayout>
  
              
        )} />

        

      <Route path="/products/:productId" component = {DetailedProduct} render={() => (
        <WithAuth>
         <HomepageLayout>
           <ProductDetails/>
         </HomepageLayout>
        </WithAuth>
      )}/>

    <Route path="/productsSurf/:productId" component = {DetailedProductSurfNote} render={() => (
        <WithAuth>
         <MainLayout>
           <DetailedProductSurfNote/>
         </MainLayout>
        </WithAuth>
      )}/>



        <Route path="/TermsOfUse" render={() => (
          <MainLayout>
              <TermsOfUse/>
              <Footer />
          </MainLayout>
           )} />

          <Route exact path="/cartpage" render={() => (
            <HomepageLayout>
              <CartPage />
            </HomepageLayout>
            )} />

          <Route exact path="/paymentpro" render={() => (
            <MainLayout>
                 <Elements stripe={promise}>
                   <PayCart />
                 </Elements>
            </MainLayout>
            )} />

      </Switch>

      <Route exact path="/shipping" render={() => (
      
            <MainLayout>
              <Shipping />
            
            </MainLayout>
            
       
              )} />

            
        <Route exact path="/orderssurf" render={() => (
        <MainLayout>
          
          <OrdersSurf />
        </MainLayout>
      
  
              
        )} />

    </div>
  );
}





export default App;
