import React from 'react';
import ProductResults from './../../components/ProductResults';
import Footer from './../../components/Footer';

const Search = ({ }) => {
  return (
    <div>
    <div className="searchPage">
      <ProductResults />
    
    </div>
  </div>

  );
};

export default Search;