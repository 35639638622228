import React from 'react';
import CartNew from '../../components/CartView';
import Footer from '../../components/Footer';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const CartPage = ({ }) => {
  return (
    <div>
      <div className="cart">
        <CartNew/>
      </div>
    </div>
  );
};
export default CartPage;