
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import axios from "axios"
import React, { useState } from 'react'
import $, { ready } from "jquery";
import './styles.scss';
import firebase from "firebase";
import { findAllInRenderedTree } from "react-dom/test-utils";
import { selectCartItems } from "../../redux/Cart/cart.selectors";
import Footer from "../Footer";



const CARD_OPTIONS = {
	iconStyle: "solid",
	style: {
		base: {
			iconColor: "#c4f0ff",
			color: "black",
			fontWeight: 100,
			fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
			fontSize: "16px",
			fontSmoothing: "antialiased",
			":-webkit-autofill": { color: "#FF6900" },
			"::placeholder": { color: "#FF6900" }
		},
		invalid: {
			iconColor: "#FF6900",
			color: "#ffc7ee"
		}
	}
}





export default function PaymentForm()   {
    const [country, setCountry] = useState('')
    const [zip, setZip] = useState('')
    const [state, setState] = useState('')
    const [city, setCity] = useState('')
    const [a2, setA2] = useState('')
    const [a1, setA1] = useState('')
    const [lastName, setLast] = useState('')
    const [firstName, setFirst] = useState('')
    //const [disabled, setDisabled] = useState(true)
    const [externalUID, setUID] = useState('')
    const [ cartItems, setCartItems] = useState('')
    
    firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          //console.log(user.uid)
          setUID(user.email)
          console.log(externalUID)
          //this.viewCart()
          // ...
          // User is signed out
        }
      });

      
    var EUID = externalUID
    const [success, setSuccess ] = useState(false)
    const stripe = useStripe()
    const elements = useElements()


    // declare params for checksum
    var crypto = require('crypto');
    const { v4: uuidv4 } = require('uuid');
    const secret = 'haOoTBU2VDsaQy';
    const myuuid = uuidv4();
    const method = "cart_order_place";
    const method2 = "cart_set_address";
    const method3 = "cart_view";
    var d = new Date();
    var date = d.toISOString()

    // generate checksum
    const hash =crypto.createHmac('sha1',  secret)
        .update(method + myuuid + date)
        .digest('base64')

    const hash2 =crypto.createHmac('sha1',  secret)
        .update(method2 + myuuid + date)
        .digest('base64')

    const hash3 =crypto.createHmac('sha1',  secret)
        .update(method3 + myuuid + date)
        .digest('base64')
        
      
    const validate = async (event) => {
        if(firstName === '' || lastName === '' || a1  === '' || city === '' || state === '' ||  zip  === '' || country  === '' )
        {
           alert('all fields are obligatory')
        }
            if(state.length > 2 || state.length < 2)
                {
                    alert('For US states, must be the two character abbreviation. ex : Georgia -> GA ')
                }
        
                    else
                    {
                        //alert('address succesfully updated')
                        //setDisabled(false)
                        const url = "https://playpay.dev.catalogapi.com/v1/jsonp/cart_set_address/?state_province="+state + '&socket_id=1272' + '&city=' +  city + '&first_name=' +firstName + '&last_name=' + lastName + '&country=US&postal_code=' + zip +  '&address_1=' +  a1 + 't&creds_checksum=' + encodeURIComponent(hash2) +'&creds_datetime=' +  encodeURIComponent(date) + '&creds_uuid=' + encodeURIComponent(myuuid) + '&external_user_id=hector@gmail.com'
                        const response =  await fetch(url,{
                        mode: 'no-cors' ,
                        
                        })
                        
                        
                        

                    
                    
                        
                
                        //window.location='/cartpage'
                    }

      }
      const placeOrder = async (event) => {
            
            await $.ajax({
                url: "https://playpay.dev.catalogapi.com/v1/jsonp/cart_order_place?socket_id=1272&creds_checksum=" + encodeURIComponent(hash) +'&creds_datetime=' +  encodeURIComponent(date) + '&creds_uuid=' + encodeURIComponent(myuuid) + '&external_user_id=' + externalUID + '&callback=?',
                mode: 'no-cors',
               //success: (data) => {
                    
                    //alert('thanks') 
                    //window.location='/search'
                    
                    //var prices = newData.cart_view_response.cart_view_result.items.CartItem
                        //const totalPrice = newData.cart_view_response.cart_view_result.items.CartItem
                    //}
        })
      }
    /*   const viewCart = async (event) => {

        const response = 
        
        //var responsetest = "";
        await $.ajax({
            url: "http://playpay.dev.catalogapi.com/v1/jsonp/cart_view?creds_checksum="+ encodeURIComponent(hash3) +'&creds_datetime=' +  encodeURIComponent(date) + '&creds_uuid=' + encodeURIComponent(myuuid) + '&external_user_id=' +EUID + '&socket_id=1272&callback=?',
            dataType: "jsonp",
                success: (data) => {
                    const newData =  data
                   
                    //this.setState({cartItems: newData.cart_view_response.cart_view_result.items.CartItem}) 
                    
                    //var prices = newData.cart_view_response.cart_view_result.items.CartItem
                        //const totalPrice = newData.cart_view_response.cart_view_result.items.CartItem
                    }
        })
    } */
      const inputchangehandler1 = (event) => {
        setFirst(event.target.value)
        
      }
      const inputchangehandler2 = (event) => {
        setLast(event.target.value)
   
      }
      const inputchangehandler3= (event) => {
        setA1(event.target.value)
       
      }
      const inputchangehandler4 = (event) => {
        setA2(event.target.value)
     
      }
      const inputchangehandler5 = (event) => {
        setCity(event.target.value)
      
      }
      const inputchangehandler6 = (event) => {
        setState(event.target.value)
       
      }
      const inputchangehandler7= (event) => {
        setZip(event.target.value)
        
      }
      const inputchangehandler8 = (event) => {
        setCountry(event.target.value)
      
      }
    const handleSubmit = async (e) => {
        validate()
        e.preventDefault()
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement)
            
        })

    
        
    if(!error) {
        try {
            
            const {id} = paymentMethod
            const response = await axios.post("http://localhost:4000/payment", {
                amount: 50,
                id
            })

            if(response.data.success) {
               
                placeOrder()
                window.location='orders'
                console.log("Successful payment")
                setSuccess(true)
            }

        } catch (error) {
           alert("Error", error)

        }
    } else {
        alert(error.message)
    }
}
   
    return (
        
        <>
                <div className='paymentDetails'>
                    <h1>Shipping Address</h1>
                   
                    <li>
                        <input className = "inputField"
                            onChange={inputchangehandler1}
                            value = {firstName}
                            id="First_Name"
                            variant="outlined"
                            name = "First Name"
                            required = "true"
                            placeholder = "First Name"
                        />
                    </li>

                    <li>
                        <input className = "inputField"
                            onChange={inputchangehandler2}
                            value = {lastName}
                            id= "Last_Name"
                            variant="outlined"
                            placeholder = "Last Name"
                        />
                    </li>

                    <li>
                        <input className = "inputField"
                            onChange={inputchangehandler3}
                            value = {a1}
                            id="address_1"
                            variant="outlined"
                            placeholder = "Address 1"
                        />
                    </li>

                    <li>
                        <input className = "inputField"
                            onChange={inputchangehandler4}
                            value = {a2}
                            id="address_2"
                            variant="outlined"
                            placeholder = "Address 2"
                        />
                    </li>

                    <li>
                        <input className = "inputField"
                            onChange={inputchangehandler5}
                            value = {city}
                            id="city"
                            variant="outlined"
                            placeholder = "City"
                        />
                    </li>

                    <li>
                        <input className = "inputField"
                             onChange={inputchangehandler6}
                            value = {state}
                            id="state_province"
                            variant="outlined"
                            placeholder = "State"
                        />
                    </li>

                    <li>
                        <input className = "inputField"
                            onChange={inputchangehandler7}
                            value = {zip}
                            id="postal_code"
                            variant="outlined"
                            placeholder = "Zip"
                        />
                    </li>

                    <li>
                        <input className = "inputField"
                            onChange={inputchangehandler8}
                            value = {country}
                            id="Country"
                            variant="outlined"
                            placeholder = "Country"
                        />
                    </li>
                   
            </div>
        
        {!success ? 
        
        <form onSubmit={handleSubmit}>
            <fieldset className="FormGroup">
                <div className="FormRow">
                    <CardElement options={CARD_OPTIONS}/>
                </div>
            </fieldset>
            <button  > Pay </button>
        </form>
        :
       <div>
           <h2>Order submitted</h2>
       </div> 
        }
        <Footer></Footer>
        </>
    )
}