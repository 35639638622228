
import React from 'react';
import GetItem from '../../components/GetItem'
import NewProductCard from '../../components/NewProductCard'
import Footer from '../../components/Footer'
import Categories from '../../components/Categories'

//mport FetchRandomUser from './../../components/GetItem';

const SearchPage = ({}) => {
  return (
  
      <div>
        <NewProductCard />
       
      </div>
  


    
  );
}


  export default SearchPage;