import React from "react";
import $ from "jquery";
import './styles.scss'
import { Grid , Button, colors} from "@material-ui/core";
import { Link, } from "react-router-dom";
import firebase from "firebase";


var db = firebase.firestore();

export default class SurfNoteProductCard extends React.Component {
  state = {
    loading: true,
    image: null,
    name:  null,
    price:  null,
    item: null,
    page: 1,
    category: 0,
    search:'',
    catalogId:  5243105,
    catName: 'All',
    products: null,
    ID:  null,
  };

  async viewCart(){
    const url = "http://playpay.dev.catalogapi.com/v1/rest/cart_add_item/";
 
    const response = await $.getJSON(url,  
            {
            socket_id: '1272',
            external_user_id: 'aa22223',
            catalog_item_id:  '5244779',
            quantity:  1,
            token: "b6ab2dc359b7b68ebb37762bac12395c10b9862c",

            },)

    const data = await response;
    console.log(data)
          }

  
   async fetchDataItem(){
    const url = "http://playpay.dev.catalogapi.com/v1/rest/view_item/";
    
    const response = await $.getJSON(url,  
            {
            catalog_item_id: (this.state.catalogId),
            token: "b6ab2dc359b7b68ebb37762bac12395c10b9862c",
            socket_id: '1272',
            per_page: '12',
            
            },)
            
    const data = await response;

    }

    async fetchData(){
        const url = "http://playpay.dev.catalogapi.com/v1/rest/search_catalog/";
        const response = await $.getJSON(url,  
                {
                token: "b6ab2dc359b7b68ebb37762bac12395c10b9862c",
                socket_id: '1272',
                per_page: '12',
                
                },)
        const data = await response;
        //console.log(data)//.catalog_breakdown_response.catalog_breakdown_result.categories.Category)
        //this.setState({result: data.catalog_breakdown_response.catalog_breakdown_result.categories.Category, loading:false })
        this.setState({item:  data.search_catalog_response.search_catalog_result.items.CatalogItem})
        this.setState({image:  data.search_catalog_response.search_catalog_result.items.CatalogItem.image_300})
        this.setState({name:  data.search_catalog_response.search_catalog_result.items.CatalogItem.name})
        this.setState({price:  data.search_catalog_response.search_catalog_result.items.CatalogItem.catalog_price})
        //console.log(this.state.item)
    }

    async fetchDataCustom(){
        const url = "http://playpay.dev.catalogapi.com/v1/rest/search_catalog/";
        const response = await $.getJSON(url,  
                {
                token: "b6ab2dc359b7b68ebb37762bac12395c10b9862c",
                socket_id: '1272',
                search: (this.state.search),
                page: (this.state.page),
                per_page: '12',
                category_id: (this.state.category),
                
                },)
        const data = await response;
        //console.log(data)//.catalog_breakdown_response.catalog_breakdown_result.categories.Category)
        //this.setState({result: data.catalog_breakdown_response.catalog_breakdown_result.categories.Category, loading:false })
        this.setState({item:  data.search_catalog_response.search_catalog_result.items.CatalogItem})
        this.setState({image:  data.search_catalog_response.search_catalog_result.items.CatalogItem.image_300})
        this.setState({name:  data.search_catalog_response.search_catalog_result.items.CatalogItem.name})
        this.setState({price:  data.search_catalog_response.search_catalog_result.items.CatalogItem.catalog_price})
        //console.log(this.state.item)
    }
    async componentDidMount() {

        this.dbCollection()
        
    }

    UserId(externalUID){
        //1 firebase.auth().currentUser.currentUser.uid
             if (firebase.auth().currentUser !== null) 
             externalUID =  firebase.auth().currentUser.uid
             return externalUID
             }

    search = event => {
        console.log('clicked')
        var keyword = document.getElementById('search_field').value
        this.setState({search: keyword}, this.fetchDataCustom)
        console.log(this.state.category)//.catalog_breakdown_response.catalog_breakdown_result.categories.Category)
      
    }
    searchSurf= () =>
    {window.location='/searchSurf'}
    
    searchAll = () => {
        window.location='/search'
      
    }

    searchAutomotive = () => {
        this.setState({category: 99},this.fetchDataCustom)
        this.setState({catName: "AUTO"})
        this.setState({page: 1})
        console.log(this.state.category)//.catalog_breakdown_response.catalog_breakdown_result.categories.Category)
      
    }
    searchElectronics = () => {
        this.setState({category: 1},this.fetchDataCustom)
        this.setState({catName: "Electronics"})
        this.setState({page: 1})
        console.log(this.state.category)//.catalog_breakdown_response.catalog_breakdown_result.categories.Category)
      
    }
    searchBaby = () => {
        this.setState({category: 233},this.fetchDataCustom)
        this.setState({catName: "Baby"})
        this.setState({page: 1})
        console.log(this.state.category)//.catalog_breakdown_response.catalog_breakdown_result.categories.Category)
      
    }
    searchBooks = () => {
        this.setState({category: 156,},this.fetchDataCustom)
        this.setState({catName: "books"})
        this.setState({page: 1})
        console.log(this.state.category)//.catalog_breakdown_response.catalog_breakdown_result.categories.Category)
      
    }
    searchDVD = () => {
        this.setState({category: 105},this.fetchDataCustom)
        this.setState({catName: "DVD"})
        this.setState({page: 1})
        console.log(this.state.category)//.catalog_breakdown_response.catalog_breakdown_result.categories.Category)
      
    }
    searchFashion = () => {
        this.setState({category: 13},this.fetchDataCustom)
        this.setState({catName: "Fashion"})
        this.setState({page: 1})
        console.log(this.state.category)//.catalog_breakdown_response.catalog_breakdown_result.categories.Category)
      
    }
    searchGiftCards = () => {
        this.setState({category: 2648},this.fetchDataCustom)
        this.setState({catName: "GIft"})
        this.setState({page: 1})
        console.log(this.state.category)//.catalog_breakdown_response.catalog_breakdown_result.categories.Category)
      
    }
    searchFood = () => {
        this.setState({category: 101},this.fetchDataCustom)
        this.setState({catName: "Food"})
        this.setState({page: 1})
        console.log(this.state.category)//.catalog_breakdown_response.catalog_breakdown_result.categories.Category)
      
    }
    searchBeauty = () => {
        this.setState({category: 107},this.fetchDataCustom)
        this.setState({catName: "Beauty"})
        this.setState({page: 1})
        console.log(this.state.category)//.catalog_breakdown_response.catalog_breakdown_result.categories.Category)
      
    }
    searchHome = () => {
        this.setState({category: 6},this.fetchDataCustom)
        this.setState({catName: "Home"})
        this.setState({page: 1})
        console.log(this.state.category)//.catalog_breakdown_response.catalog_breakdown_result.categories.Category)
      
    }
    searchOffice = () => {
        this.setState({category: 28},this.fetchDataCustom)
        this.setState({catName: "Office"})
        this.setState({page: 1})
        console.log(this.state.category)//.catalog_breakdown_response.catalog_breakdown_result.categories.Category)
      
    }
    searchSports = () => {
        this.setState({category: 248},this.fetchDataCustom)
        this.setState({catName: "Sports"})
        this.setState({page: 1})
        console.log(this.state.category)//.catalog_breakdown_response.catalog_breakdown_result.categories.Category)
      
    }
    searchToys = () => {
        this.setState({category: 234},this.fetchDataCustom)
        this.setState({catName: "Toys"})
        this.setState({page: 1})
        console.log(this.state.category)//.catalog_breakdown_response.catalog_breakdown_result.categories.Category)
      
    }
    searchVG = () => {
        this.setState({category: 5},this.fetchDataCustom)
        this.setState({catName: "Video Games"})
        this.setState({page: 1})
        console.log(this.state.category)//.catalog_breakdown_response.catalog_breakdown_result.categories.Category)
      
    }
    dbCollection = () => {

        db.collection('products')
       .get()
       .then(querySnapshot => {
       const document = querySnapshot.docs.map(doc => doc.data());
       
       
       this.setState({ products: document});
       
       db.collection('products')
       .get()
       .then(querySnapshot => {
       const docId = querySnapshot.docs.map(doc => doc.id);

       this.setState({ ID: docId});
      
       })
       

       })


   }
    render() {
       

        const onNextEvt = () => {
            if (this.state.page >= 1){
              this.setState({page: (this.state.page)+1}, this.fetchDataCustom)
      
            }
        }

        const onPrevEvt = () => {
            if (this.state.page > 1){
                this.setState({page: (this.state.page)-1}, this.fetchDataCustom)
                this.fetchData()
            }
      
        }
        
        const data2= (this.state.ID)
        console.log(data2)
        const data = this.state.products
        console.log(data)
                
       //var data =  (this.state.products)
    
        return (

        <div>
            <header className = 'catBar'>
                <ul>

                    <Button className = 'liALL ' onClick = {this.searchAll}>
                     <p>ALL</p>
                    </Button>  

                     <Button className = 'liSu ' onClick = {this.searchSurf}>
                     <p>Surfnote</p>
                    </Button> 


                    <Button className = 'liRN ' onClick = {this.searchElectronics}>
                     <p>electronics</p>
                    </Button>   

                    <Button className = 'liG' onClick = {this.searchAutomotive}>
                    <p>Auto</p>       
                    </Button>
                    
                    <Button className = 'liLG' onClick = {this.searchBaby}>
                    <p>Baby</p>
                    </Button>

                    <Button className = 'liYE' onClick = {this.searchBooks}>
                    <p>Books</p>
                   </Button>

                   <Button className = 'liBN' onClick = {this.searchDVD}>
                    <p>DVD</p>
                   </Button>

                   <Button className = 'liDB' onClick = {this.searchFashion}>
                    <p>Fashion</p>
                   </Button>

                   <Button className = 'liP' onClick = {this.searchGiftCards}>
                    <p>Gift cards</p>
                   </Button> 

                   <Button className = 'liPI' onClick = {this.searchFood} >
                    <p>Food</p>
                   </Button>

                   <Button className = 'liGR' onClick = {this.searchBeauty}>
                    <p>Beauty</p>
                   </Button>

                   <Button className = 'liM'onClick = {this.searchHome}>
                    <p>Home</p>
                   </Button>

                   <Button className = 'liO' onClick = {this.searchOffice}>
                    <p>Office</p>
                   </Button>

                   <Button className = 'liBDD'  onClick = {this.searchSports}>
                    <p>Sports</p>
                   </Button>


                   <Button className = 'liDG' onClick = {this.searchToys}>
                    <p>Toys</p>
                   </Button>

                   <Button className = 'liA' onClick = {this.searchVG}>
                    <p>Video Games</p>
                   </Button>
                </ul>
       
      </header>

            <div className = "searchZone">
               
                <Button className = 'categorieIndicator 'onClick={() => {this.searchAll()}}>
                    <p>{'surfnote'}</p>
                </Button>
                <input className = "searchbar"
                    id="search_field"
                    variant="outlined"
                />
                 <Button className = 'searchButton 'onClick={() => {this.search()}}>
                    <p>Search</p>
                </Button>

            </div>
                <div className="productsContainer">
                    
                    < Grid container  justify="center"   >
                    {
   
                                data && data.length>0 && data.map((item)=>   
                                <Link className = 'column' to={"/productsSurf/" + item.ID}>
                                        <img src={item.productThumbnail150} />
                                        <div></div>
                                        
                                             {item.productName }
                                    
                                        <h2>
                                            $ {item.productPrice} 

                                        </h2>  
                                  
                                    </Link> 
                                    )}
                                   
                    </Grid> 
                </div>    
                <div className="pagination">
                <ul>
                    <li>
                    <span >
                        Prev
                    </span>
                    </li>
                    <li>
                    <h1 >
                        {this.state.page}
                    </h1>
                    </li>
                    <li>
                    <span >
                        Next
                    </span>
                    </li>
                </ul>
                </div>
        </div>
        
            );
        }

}


   

