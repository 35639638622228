import React, { useDebugValue } from "react";
import $, { ready } from "jquery";
import './styles.scss'
import { table, Grid , Button} from "@material-ui/core";
import { Link, Redirect} from 'react-router-dom'
import Header from "../Header";
import Footer from "../Footer";
import firebase from "firebase";
import { emailSignInStart } from "../../redux/User/user.actions";
import { stringify } from "querystring";



// declare params for checksum
var db = firebase.firestore();
var crypto = require('crypto');
const { v4: uuidv4 } = require('uuid');
const secret = 'haOoTBU2VDsaQy';
const myuuid = uuidv4();
const method = "cart_view"
var d = new Date();
var date = d.toISOString()

// generate checksum
const hash =crypto.createHmac('sha1',  secret)
    .update(method + myuuid + date)
    .digest('base64')

const hashPlusItem =crypto.createHmac('sha1',  secret)
    .update("cart_add_item" + myuuid + date)
    .digest('base64')

const hashMinusItem =crypto.createHmac('sha1',  secret)
    .update("cart_remove_item" + myuuid + date)
    .digest('base64')

 
//const email = user.email;



//get user UID


export default class cartNew extends React.Component {

    state = {
        cartItems:  null,
        cartDetails: null,
        cartTotals: null,
        externalUID: null,
        totalPrices: '',
        productsInCart: null,
        cart: [],
        quantity:  null,
    }
    AuthData(){
    firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          //console.log(user.uid)
          const UID = user.email
          const newUID = UID.slice(0, -4)
          this.setState({externalUID: newUID })
          console.log(this.state.externalUID)
          this.viewCart()
          // ...
          // User is signed out
          // ...
        }
      });
    }
    async viewCart(){

        const response = 
        
        //var responsetest = "";
        await $.ajax({
            url: "http://playpay.dev.catalogapi.com/v1/jsonp/cart_view?creds_checksum="+ encodeURIComponent(hash) +'&creds_datetime=' +  encodeURIComponent(date) + '&creds_uuid=' + encodeURIComponent(myuuid) + '&external_user_id=' + this.state.externalUID + '&socket_id=1272&callback=?',
            dataType: "jsonp",
                success: (data) => {
                    const newData =  data
                    this.setState({cartItems: newData.cart_view_response.cart_view_result.items.CartItem}) 
                    
                    //var prices = newData.cart_view_response.cart_view_result.items.CartItem
                        //const totalPrice = newData.cart_view_response.cart_view_result.items.CartItem
                    }
                    
        })
        //const newData = await response
        //this.setState = ({cartItems: newData.cart_view_response.cart_view_result.items.CartItem}) 
        //this.setState({items: responsetest.cart_view_response.cart_view_result.items.CartItem })
        //await console.log(this.state.cartItems)
       
    }
    async plusItem(){
        
        //var responsetest = "";
        
        //const newData = await response
        //this.setState = ({cartItems: newData.cart_view_response.cart_view_result.items.CartItem}) 
        //this.setState({items: responsetest.cart_view_response.cart_view_result.items.CartItem })
        //await console.log(this.state.cartItems)
       
    }
    onSubmit = () => {
         <Redirect  to="/posts/" />
     }
    getArray(totalPrices, taxes, fullPrice){
        function getArraySum(a){
            var total=0;
            for(var i in a) { 
                total += a[i];
            }
            return total.toFixed(2);
        }
        var prices = this.state.cartItems
        const newPrices =  prices && prices.length>0 && prices.map((item)=>
        (item.catalog_price)*(item.quantity)
        )

        var sEst = this.state.cartItems
        const newEst =  sEst && sEst.length>0 && sEst.map((item)=>
        (item.shipping_estimate)*(item.quantity)
        )

        var prices2 = this.state.cart
        const newPrices2 =  prices2 && prices2.length>0 && prices2.map((item)=>
        (item.price)*(item.quantity)
        )


        var sEst2 = this.state.cart
        const newSEst2 =  sEst2 && sEst2.length>0 && prices2.map((item)=>
        (item.SEstimate)*(item.quantity)
        )
        //console.log(newPrices2)
        const totalPrices1 = (getArraySum(newPrices))
        const totalShipping1 = (getArraySum(newEst))
        const totalPrices2 = (getArraySum(newPrices2))
        const totalShipping2 = (getArraySum(newSEst2))
        const totalPricesNew = [Number(totalPrices1) , Number(totalShipping1) , Number(totalPrices2),  Number(totalShipping2)]

        totalPrices = (getArraySum(totalPricesNew))
        taxes = (totalPrices * 8.9 /100).toFixed(2)

        const newTotalPrices = [Number(totalPrices), Number(taxes)]

        fullPrice = (getArraySum(newTotalPrices))


        //console.log(totalPrices)
        return [totalPrices, taxes, fullPrice]
       

    }
    getProducsSurf(){
       
        // firebase.auth().onAuthStateChanged((user) => {
        //     if (user) {

        //     const docRef  = db.collection('cart').doc(user.email)
        //     docRef.get().then((doc) => {
        //         if (doc.exists) {
        //             const documentID = doc.data().Products
        //             this.setState({ productsInCart: doc.data()});
        //             this.setState({ quantity: doc.data().quantity});
        //             //console.log(doc.data().Products)
        //             //console.log(doc.data().quantity)
        //             //console.log(documentID)

                     
        //     firebase.auth().onAuthStateChanged((user) => {
        //         if (user) {
        //             db.collection("cart").where(firebase.firestore.FieldPath.documentId(), 'in', documentID)
        //             .get()
        //             .then((querySnapshot) => {
        //                 var work = []
        //                 querySnapshot.forEach((doc2) => {
        //                     work  = work.concat(doc2.data())
        //                     //console.log(newArr)
                            
        //                  });
        //                  this.setState({ cart: work});
        //                  //console.log(work)
                         
        //             })
        //             .catch((error) => {
        //                 console.log("Error getting documents: ", error);
        //             });
                    
                    
        //             //console.log("Document data:", doc.data());
        //             //console.log("Document data2:", doc.data().quantity);
        //         } else {
        //             // doc.data() will be undefined in this case
        //             console.log("No such document!");
        //         }
        //     }).catch((error) => {
        //         console.log("Error getting document:", error);
        //     });

        //     }
            
        // })
        // cart/externalUID/items/**itemID --- productID,name,price,SEstimate,quantity
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                var cart = []
                db.collection("cart").doc(this.state.externalUID).collection("items").get()
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        if(doc.data().quantity <= 0){
                            db.collection("cart").doc(this.state.externalUID).collection(this.state.externalUID).doc(doc.id).delete().then(() => {
                                //cart  =doc.data()
                                this.setState({ cart: cart})
                                console.log("Document successfully deleted!");
                            }).catch((error) => {
                                console.error("Error removing document: ", error);
                            });
                        }else{
                            cart  = cart.concat(doc.data())
                            this.setState({ cart: cart})
                        

                        }
                       
                        //console.log(doc.data());
                    });
                    //console.log(cart)
                });
            }
        })
    }
    passCartPrice(){

        var currentPrices =
        db.collection('cart').doc(this.state.externalUID)
            .collection('total').doc('currentCar');
        //get document reference
        currentPrices.get().then((doc) => {
            //check if reference exist
                if (doc.exists) {
                    //if exist update
                    currentPrices
                        .update({
                       subTotal:  this.getArray().[0],
                       taxes: this.getArray().[1],
                       total: this.getArray().[2]
                        })
                        .then(() =>  {
                            console.log('Document updated ');
                            {window.location='/shipping'}
                        })
                        .catch((error) => {
                            console.log('Error updating document: ', error);
                        });  
                    //if does not exist create 
                }else{
                    currentPrices
                        .set({
                        subTotal:  this.getArray().[0],
                        taxes: this.getArray().[1],
                       total: this.getArray().[2]
                        })
                        .then(() =>  {
                            console.log('Document Added ');
                            {window.location='/shipping'}
                           
                        })
                        .catch((error) => {
                            console.log('Error adding document: ', error);
                        });   

                }
        })
            

    }
    componentDidMount(){
            this.AuthData()
            this.getProducsSurf()
           
           
           // console.log(this.state.productsInCart)

      
        //getUserId for cart


       
    }
    render(){
       
        const data = this.state.cartItems 
        this.getArray()
        
        //console.log(data)
   
        const data2= this.state.cart
        
        //console.log(data2)
        //console.log(data3)
        
        
        

        return(

            
            
           <div className = 'mainContainer' direction = 'row' >
              
                <div  className = "itemsContainer" >
                    {
               
                    data2 && data2.length>0 && data2.map((item) =>
                    
                    <Grid container spacing={3} >
                        <Grid container  justify="flex-start" className = "card" >
                                <Grid item  >
                                        <div>
                                            <div className= 'productImage'>
                                                <img src={item.thumbnail75}/>
                                            </div>  
                                        </div>
                                </Grid>
                                <Grid item  className= 'detailColumn'>
                                    <Grid item xs container direction="column">
                                        <h1>
                                            {item.name}
                                        </h1>
                                        <h2>
                                            $ {item.price}
                                        </h2>
                                        <h3>
                                            Shipping : $ {item.SEstimate}
                                        </h3>
                                       
                
                                    </Grid>
                                </Grid>    
                                  
                                        <Grid item  className= 'quantityColumn'>
                                       
                                        <Grid item xs container direction="column" >
                                    

                                            <h1>
                                                Quantity
                                            </h1>
                                            
                                          
                                            <h2   >
                                                {item.quantity}
                                            </h2>
                                           
                                          
                                        <Grid item xs container direction="row">

                                            <Button className = "buttonAdd" onClick = {() => {
                                                    
                                                    var currentQuantity = item.quantity
                                                    //console.log(currentQuantity)
                                                    var newQuantity = currentQuantity - 1
    
                                                    console.log(newQuantity)
    
                                                    var itemsRef2 =
                                                    db.collection('cart').doc(this.state.externalUID)
                                                        .collection('items');
                                                        
                                                    itemsRef2
                                                        .doc(item.productID)
                                                        .update({
                                                        quantity: newQuantity
                                                        }).then(() =>  {
                                                            this.getProducsSurf()
                                                        })
                                            }}>
                                                <p>-</p>
                                            </Button>
                                           
                                            <Button className = "buttonAdd" onClick = {() => { 
                                                
                                               
                                                var currentQuantity = item.quantity
                                                //console.log(currentQuantity)
                                                var newQuantity = currentQuantity + 1

                                                console.log(newQuantity)

                                                var itemsRef2 =
                                                db.collection('cart').doc(this.state.externalUID)
                                                    .collection('items');
                                                    
                                                itemsRef2
                                                    .doc(item.productID)
                                                    .update({
                                                    quantity: newQuantity
                                                    }).then(() =>  {
                                                        this.getProducsSurf()
                                                    })
                                                    
                                            
                                                }}>
                                                <p>+</p>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    
                                </Grid>            
                        </Grid>     
                    </Grid>             
                    )}
                </div>
                <div  className = "itemsContainer" >
                    {
                    data && data.length>0 && data.map((item)=>
                    <Grid container spacing={3} >
                        <Grid container  justify="flex-start" className = "card" >
                                <Grid item  >
                                        <div>
                                            <div className= 'productImage'>
                                                <img src={item.image_uri}/>
                                            </div>  
                                        </div>
                                </Grid>
                                <Grid item  className= 'detailColumn'>
                                    <Grid item xs container direction="column">
                                        <h1>
                                            {item.name}
                                        </h1>
                                        <h2>
                                            $ {item.cart_price}
                                        </h2>
                                        <h3>
                                            Shipping : $ {item.shipping_estimate}
                                        </h3>
                                       
                
                                    </Grid>
                                </Grid>       
                                <Grid item  className= 'quantityColumn'>
                                    <Grid item xs container direction="column" >
                                        <h1>
                                            Quantity
                                        </h1>
                                        <h2>
                                            {item.quantity}
                                        </h2>
                                        <Grid item xs container direction="row">

                                            <Button className = "buttonAdd" onClick = {() => {
                                                    
                                                    const productId = item.catalog_item_id
                                                    console.log(productId)
                                                    const response =  $.ajax({
                                                        url: "http://playpay.dev.catalogapi.com/v1/jsonp/cart_remove_item?creds_checksum="+ encodeURIComponent(hashMinusItem) +'&creds_datetime=' +  encodeURIComponent(date) + '&creds_uuid=' + encodeURIComponent(myuuid) +'&catalog_item_id=' + productId +'&quantity=1&external_user_id=' + this.state.externalUID + '&socket_id=1272&callback=?',
                                                        dataType: "jsonp",
                                                            success: (data) => {
                                                                this.viewCart()
                                                            }
                                                        
                                                    }) 
                                            }}>
                                                <p>-</p>
                                            </Button>
                                            <Button className = "buttonAdd" onClick = {() => {
                                                    const productId = item.catalog_item_id
                                                    console.log(productId)
                                                    const response =  $.ajax({
                                                        url: "http://playpay.dev.catalogapi.com/v1/jsonp/cart_add_item?creds_checksum="+ encodeURIComponent(hashPlusItem) +'&creds_datetime=' +  encodeURIComponent(date) + '&creds_uuid=' + encodeURIComponent(myuuid) +'&catalog_item_id=' + productId + '&quantity=1&external_user_id=' + this.state.externalUID + '&socket_id=1272&callback=?',
                                                        dataType: "jsonp",
                                                            success: (data) => {
                                                                this.viewCart()
                                                            }
                                                        
                                                    }) 
                                            }}>
                                                <p>+</p>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    
                                </Grid>            
                        </Grid>     
                    </Grid>             
                    )}
                </div>
                <Grid  container spacing={3} className = "cartData" >
                    <Grid container  spacing={3} className = "card" >
                        <h1>
                            Cart total
                            <h2>
                            ${this.getArray().[0]}
                            </h2>
                        </h1>
                            <Button className = "buttonCheckout"  onClick = {() => this.passCartPrice()}>
                                <p>Checkout</p>
                            </Button>

                            <Button className = "buttonKeepShopping"  onClick = {() => {window.location='/search'}}>
                                <p>Keep shopping</p>
                            </Button>

                            <Button className = "buttonKeepShopping"  onClick = {() => {window.location='/termsofuse'}}>
                                <p>View return policy</p>
                            </Button>


                    </Grid>                 
                </Grid>
            </div>
        );
    }
}

