import React from 'react';
import Rewards from './../../assets/lotixRewards.png';
import Shop from './../../assets/lotixShop.png';
import { Link } from 'react-router-dom';
import './styles.scss';




const Directory = props => {

  return (
   
    <div className="directory">
      <div className="wrap">
        <div
          className="item"
          style={{
            backgroundImage: `url(${Shop})`
          }}
        >
          <Link to="/search">
            Go
          </Link>
          
        </div>
        <div
          className="item"
          style={{
            backgroundImage: `url(${Rewards})`
          }}
        >
        </div>
      </div>
    </div>
  );
};

export default Directory;