import React from "react";
import {Grid, Divider, Button} from "@material-ui/core";
import {AccountCircle} from "@material-ui/icons";
import './styles.scss';

//import SvgIcon from '@material-ui/core/SvgIcon';



export default class Orders extends React.Component {
    hide(){
        //var grid = document.getElementById("verticalNav2").className 
        if( document.getElementById("verticalNav2").className === "verticalNav"){
            document.getElementById("verticalNav2").className = "verticalNavLeft"
           
        }else{
            document.getElementById("verticalNav2").className = "verticalNav"
        
        }
      
    }
    //show(){
        //document.getElementById("verticalNav2").className = "verticalNav"
        //console.log('show')
    //}
   
    render (){
        
        return(
            <div>
                <  Grid container  direction = 'row' spacing= {12}  >
                    <Grid item direction = 'row' className = "verticalNav" id = 'verticalNav2'  onClick={() =>{this.hide()}} >
                        <Grid className  >
                            <Button className = 'navbutton'  >
                            </Button >
                            <li>
                            <AccountCircle className = 'accountIcon'/>
                            </li>

                            <li>
                                First Name   Last name
                            </li>

                            <Divider className = 'dividerT' >
                            </Divider>
                            {/* <Button className = 'navbutton'>
                                <p>User information</p>
                            </Button> */}
                            {/* <Button className = 'navbutton'>
                                <p>Change Address</p>
                            </Button> */}
                            <Button className = 'navbutton'>
                                <p>Orders Surfnote</p>
                            </Button>
                            {/* <Button className = 'navbutton'>
                                <p>help</p>
                                
                            </Button> */}
                             {/* <Button className = 'navbutton'>
                                <p>Orders LOT.™</p>
                            </Button> */}
                            <Divider className = 'dividerT'></Divider>
                            <Button className = 'navbutton' onClick={() =>{this.hide(); {this.hide()}} } >
                                <p>close</p>
                            </Button>
                        </Grid>


                    </Grid>
                    <Grid container direction = 'row' className = "screen" justifyContent="center"    >
                        <Grid item   className = "card"  >
                           <div>
                                ORDERS SURFNOTE
                           </div>
                               
                           
                            <Divider className = 'dividerT'></Divider>
                                    <Grid item  >
                                            <div>
                                                <h1 >
                                                    ORDERID
                                                </h1>  
                                            </div>
                                            
                                            <div>
                                                <h2 >
                                                    Date Created
                                                </h2>  
                                            </div>
                                            <div>
                                                <h3>
                                                    status
                                                 </h3> 
                                            </div>
                                            <Divider className = 'dividerT'></Divider>
                                           
                                    </Grid>
                                    
                            </Grid>
                    </Grid>
                   
                </Grid>
            </div>
        );
      }
    };
