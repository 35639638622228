import React from 'react';
import Orders from '../../components/OrdersSurf';
import './styles.scss';


const OrdersSurf = props => {
  return (
    <section className="ordersList">
      <Orders />
      
    </section>
  );
};

export default OrdersSurf; 