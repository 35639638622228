import React from "react";
import $, { ready } from "jquery";
import './styles.scss'
import { Grid , Button} from "@material-ui/core";
import { Link , useHistory} from 'react-router-dom'
import Header from "../Header";
import Footer from "../Footer";
import { homedir } from "os";
import firebase from "firebase";


var db = firebase.firestore();

var crypto = require('crypto');
const { v4: uuidv4 } = require('uuid');

const secret = 'haOoTBU2VDsaQy';
const myuuid = uuidv4();
const method = "cart_add_item"
const method2 = "cart_set_address"
//var myuuid = 'b93cee9d-dd04-4154-9b5a-8768971e72b8';
var d = new Date();
var date = d.toISOString()
//var d  = '2013-01-01T01:30:00Z';
//var test = "3456789";
const userID =  firebase.auth().currentUser

const hash =crypto.createHmac('sha1',  secret)
    .update(method + myuuid + date)
    .digest('base64')

const hashTest =crypto.createHmac('sha1',  secret)
    .update(method2 + myuuid + date)
    .digest('base64')

//console.log(d);
//console.log(d.toISOString());
//console.log('checksum is: ' cart_view + hash +   )
//console.log('your hash is: ' + hash  );
//console.log('your UUID is: ' + myuuid  );

export default class DetailedProductSurfNote extends React.Component {
  
  state = {
    loading: true,
    brand: null,
    image: null,
    name:  null,
    price:  null,
    SEstimate: null,
    details: null,
    item: null,
    category: 0,
    //catalogId: this.props.match.params.productId,
    externalUID : null,
    productID: this.props.match.params.productId
    
  };

  getAccount(){
     firebase.auth().onAuthStateChanged((user) => {
            if (user) {
              // User is signed in, see docs for a list of available properties
              // https://firebase.google.com/docs/reference/js/firebase.User
              //console.log(user.uid)
              const UID = user.email
              const newUID = UID.slice(0, -4)
              this.setState({externalUID: newUID })
              console.log(this.state.externalUID)
              // ...
              // User is signed out
              // ...
            }
          });
  }

  addToCart(){
    
    var cartRef = db.collection("cart").doc(this.state.externalUID);
  
    // // Atomically add a new region to the "regions" array field.
    // cartRef.get().then((doc) => {
    // if (doc.exists) {
    //   cartRef.update({
    //     Products: firebase.firestore.FieldValue.arrayUnion(this.state.productID)
    //   }) 
    //   .then(() => {
    //     console.log("Document successfully updated!");
    //     {window.location='/cartpage'}
    //      })
    //     .catch((error) => {
    //     console.error("Error writing document: ", error);
    //      })
    // } else {
    //       db.collection("cart").doc(this.state.externalUID).set({
    //       Products: [this.state.productID],
    // })
    // .then(() => {
    //   console.log("Document successfully written!");
    //   {window.location='/cartpage'}
    //    })
    //   .catch((error) => {
    //   console.error("Error writing document: ", error);
    //    })
    //   }
    // })

    // db.collection('cart').doc(this.state.externalUID).collection('items').doc(this.state.productID).set({
    //   name: this.state.name,
    //   price: this.state.price,
    //   shipping: this.state.SEstimate,
    //   quantity: 1

    // })

    // cart/externalUID/items/**itemID --- productID,name,price,SEstimate,quantity
    // var checkDoc = db.collection("cart").doc(this.state.externalUID)
    //                  .collection('items').doc2(this.state.productID);

    //     checkDoc.get().then((doc2) => {
    //       if (doc2.exists) {
    //          checkDoc.update({
                 
    //             }) 

      var itemsRef1 =
             db.collection('cart').doc(this.state.externalUID)
               //.collection('items');
      var itemsRef2 =
               db.collection('cart').doc(this.state.externalUID)
                 .collection('items');

          itemsRef1.set({dummy: 'dummy'})
          .then(() =>  {
              console.log('Document Added ');

            itemsRef2
              .doc(this.state.productID)
              .set({
                productID: (this.state.productID),
                name: (this.state.name),
                price: (this.state.price),
                SEstimate: (this.state.SEstimate),
                thumbnail75: (this.state.image75),
                quantity: 1
              })
              .then(() =>  {
                  console.log('Document Added ');
                  {window.location='/cartpage'}
              })
              .catch((error) => {
                  console.log('Error adding document: ', error);
              });
     
          })
          .catch((error) => {
              console.log('Error adding document: ', error);
          });

     

       
 
};
      


  
  

  async viewCart(){

    
    
    const url = "http://playpay.dev.catalogapi.com/v1/jsonp/cart_add_item/?creds_checksum="+ encodeURIComponent(hash) +'&creds_datetime=' +  encodeURIComponent(date) + '&creds_uuid=' + encodeURIComponent(myuuid) + '&external_user_id=' + this.state.externalUID + '&socket_id=1272' +  '&catalog_item_id=' + this.state.catalogId+ '&quantity=1'
    const response =  await fetch(url,{
      mode: 'no-cors',
    })
    
    const data = await response;
    console.log(data)
    window.location='/cartpage'
    

  }
  async viewCart2(){
    const url = "http://playpay.dev.catalogapi.com/v1/rest/cart_view/?creds_checksum="+ encodeURIComponent(hash) +'&creds_datetime=' +  encodeURIComponent(date) + '&creds_uuid=' + encodeURIComponent(myuuid) + '&external_user_id=1&socket_id=1272&quantity=1&catalog_item_id=' + this.state.catalogId
    const response =  await $.getJSON(url,)
    const data = await response;
    console.log(data)

  }
  
  dbCollection = () => {

    var docRef = db.collection("products").doc(this.state.productID);
    

    docRef.get().then((doc) => {
        if (doc.exists) {
            console.log("Document data:", doc.data());
            this.setState({ name: doc.data().productName});
            this.setState({ details: doc.data().productDesc});
            this.setState({ price: doc.data().productPrice});
            this.setState({ brand: doc.data().productBrand});
            this.setState({ image: doc.data().productThumbnail300});
            this.setState({ image75: doc.data().productThumbnail75});
            this.setState({ SEstimate: doc.data().productShipping});
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    }).catch((error) => {
        console.log("Error getting document:", error);
    });
    


  }

  async componentDidMount() {    


      this.dbCollection()
      
      this.getAccount()
     
     
      
       
    }
    render() {
      
      
        return (
          <div>
            <Header/>
            <Grid container className= 'container'>
              <Grid item className = 'column1'>
                  <div className="hero">
                    <img src= {this.state.image} />    
                    </div>
              </Grid>
              <Grid item  className = 'column2'>
                  <div className = 'productCard'>
                    <ul>
                      <li>
                      <h1>
                        </h1>
                        <h1>
                          {this.state.name}
                        </h1>
                      </li>
                      <li>
                        <span className = 'price'>
                          ${this.state.price}
                        </span>
                      </li>
                      <li>
                        <span className = 'shipping'>
                        Shipping: ${this.state.SEstimate}
                        </span>
                      </li>
                      <li>
                        <span  className = 'brand'>
                        Brand: {this.state.brand}
                        </span>
                      </li>
                    </ul>
                    <div >
                      <Button className="addToCart" onClick={() => {this.addToCart()}}>
                        <p>
                          Add to cart
                        </p>
                      </Button>

                
                    </div>
                    <div>
                    <Link className="viewPolicy" to = "/TermsOfUse">
                          <p>
                            view return policy
                          </p>
                      </Link>
                    </div>
                </div>
              </Grid>
                
                  <div  dangerouslySetInnerHTML={{ __html: this.state.details }} className = "description">
                    
                  </div>
              
            </Grid> 
            <Footer/> 
          </div>  
      
           
        );
      }

}
   
//className = "description"
// {this.state.details}
