import React from 'react'
import firebase from "firebase";
import Footer from '../Footer';
import { Grid , Button, TextField} from "@material-ui/core";
import payApp from './../../assets/payApp.png';
import banner from './../../assets/CheckOutBg.png';
import $, { ready } from "jquery";
import './styles.scss';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';

/*
 Component "Pay" handles:
 - set address
 - checkn final price
 - compare spot-wallet balance against final price
 - place order

 component "Pay" inputs:
 - from cart to component

 component "Pay" outputs:
 -from component to add balance
 -from component to header
 -from component to error page
 -from component to orders in account dashboard.
*/

// declare firebase
var db = firebase.firestore();

//checksum
var crypto = require('crypto');
const { v4: uuidv4 } = require('uuid');
const secret = 'haOoTBU2VDsaQy';
const myuuid = uuidv4();
const method = "cart_set_address"
var d = new Date();
var date = d.toISOString()

// generate checksum
const hash =crypto.createHmac('sha1',  secret)
    .update(method + myuuid + date)
    .digest('base64')

export default class ShippingAddress extends React.Component {

    state = {
        balance : null,
        externalUID:  null,
        firstName: '',
        lastName: null,
        a1: null,
        city: null,
        state:  null,
        zip: null,
        country: null,
        phone:  null,

    //define address fields
    }
    getAccount(){
        firebase.auth().onAuthStateChanged((user) => {
               if (user) {
                 // User is signed in, see docs for a list of available properties
                 // https://firebase.google.com/docs/reference/js/firebase.User
                 //console.log(user.uid)
                 const UID = user.email
                 const newUID = UID.slice(0, -4)
                 this.setState({externalUID: newUID })
                 console.log(this.state.externalUID)
                 
                 // ...
                 // User is signed out
                 // ...
               }
             });
     }
    //Get balance from fireBase
    fetchBalance(){ 
        //create reference user/document/balance
        var docRef = db.collection("users");
        //create query against the collection email = externalUID
        var query = docRef.where("email", "==", this.state.externalUID);
    

        db.collection("users").where("email", "==", this.state.externalUID)
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                this.setState({balance: doc.data().balance })
                
            });
        })
        .catch((error) => {
            console.log("Error getting documents: ", error);
        });
    }
    //update addres catalogAPI



    componentDidMount(){
        this.getAccount()
        

    }
    
    //inputs onChange events
    fnChange =  (event) => { 
        this.setState({firstName: document.getElementById('standard-FN').value})
        this.setState({lastName: document.getElementById('standard-LN').value})
        this.setState({a1: document.getElementById('standard-A1').value})
        this.setState({a2: document.getElementById('standard-A2').value})
        this.setState({city: document.getElementById('standard-City').value})
        this.setState({state: document.getElementById('standard-State').value})
        this.setState({zip: document.getElementById('standard-Zip').value})
        this.setState({country: document.getElementById('standard-Country').value})
        this.setState({phone: document.getElementById('standard-Phone').value})
    }

    
    async logAllData(){
       //create cart for catalog API
        const url = "https://playpay.dev.catalogapi.com/v1/jsonp/cart_set_address/?state_province="+ this.state.state + '&socket_id=1272&city=' +  this.state.city + '&first_name=' + this.state.firstName + '&last_name=' + this.state.lastName + '&country=US&postal_code=' + this.state.zip +  '&address_1=' +  this.state.a1 + 't&creds_checksum=' + encodeURIComponent(hash) +'&creds_datetime=' +  encodeURIComponent(date) + '&creds_uuid=' + encodeURIComponent(myuuid) + '&external_user_id=' + this.state.externalUID
        let response =  await $.ajax(url,{
            dataType: "jsonp", 
            //on succes update firebase user with shipping information.
                success: (data) => {

                    //write document reference
                    var addressRef2 =
                    db.collection('cart').doc(this.state.externalUID)
                        .collection('address').doc('address1');
                    //get document reference
                    addressRef2.get().then((doc) => {
                        //check if reference exist
                            if (doc.exists) {
                                //if exist update
                                addressRef2
                                    .update({
                                    firstName: this.state.firstName,
                                    lastName: this.state.lastName,
                                    a1: this.state.a1,
                                    a2: this.state.a2,
                                    city: this.state.city,
                                    state: this.state.state,
                                    zip: this.state.zip,
                                    country: this.state.country,
                                    phone: this.state.phone,
                                    email: this.state.externalUID + '.com',
                                    })
                                    .then(() =>  {
                                        console.log('Document updated ');
                                        {window.location='/pay'}
                                    })
                                    .catch((error) => {
                                        console.log('Error updating document: ', error);
                                    });  
                                //if does not exist create 
                            }else{
                                
                                addressRef2
                                    .set({
                                    firstName: this.state.firstName,
                                    lastName: this.state.lastName,
                                    a1: this.state.a1,
                                    a2: this.state.a2,
                                    city: this.state.city,
                                    state: this.state.state,
                                    zip: this.state.zip,
                                    country: this.state.country,
                                    phone: this.state.phone,
                                    email: this.state.externalUID + '.com',
                                    })
                                    .then(() =>  {
                                        console.log('Document Added ');
                                        {window.location='/pay'}
                                       
                                    })
                                    .catch((error) => {
                                        console.log('Error adding document: ', error);
                                    });   

                            }
                    })
                        
                       
               
                  
                },
                error: () =>{
                        alert('All fields are reuiered')

                    
                }
        })  
    }
    
    render(){
            return(
                <div>
                    <Grid container className = "grid">
                        <Grid item className = "column2">
                                <h1>
                                  Shipping address
                                </h1> 
                                <h2>
                                  All fields are requiered
                                </h2> 
                                <div >
                                <TextField  className = 'textFields' error = {false} required id="standard-FN" label="First name" defaultValue="" onChange = {this.fnChange} >   </TextField>
                                <TextField className = 'textFields'  required id="standard-LN" label="Last name" defaultValue="" onChange = {this.fnChange} >  </TextField>
                                <TextField className = 'textFields' required id="standard-A1" label="Address 1" defaultValue="" onChange = {this.fnChange}>   </TextField>
                                <TextField className = 'textFields' required id="standard-A2" label="Address 2" defaultValue=""  onChange = {this.fnChange}>   </TextField>
                                <TextField className = 'textFields' required id="standard-City" label="City" defaultValue=""   onChange = {this.fnChange}>  </TextField>
                                <TextField className = 'textFields' required id="standard-State" label="State (e.g: GA)" defaultValue="" onChange = {this.fnChange} >   </TextField>
                                <TextField className = 'textFields' required id="standard-Zip" label="Zip" defaultValue=""  onChange = {this.fnChange}>   </TextField>
                                <TextField className = 'textFields' required id="standard-Country" label="Country" defaultValue="" onChange = {this.fnChange} >   </TextField>
                                <TextField className = 'textFields' required id="standard-Phone" label="Phone" defaultValue=""   onChange = {this.fnChange} > </TextField>
                                </div>
                              
                                <Button className = 'callToAction' onClick={() => {this.fnChange() ; this.logAllData()}} >
                                    <p> Continue </p>
                                </Button>
                        

                        </Grid>
                        <Grid item className = "column3">
                            <img src ={banner} />
                        </Grid>
                        <Footer />
                    </Grid>
                   
                </div>
            );
        }
}
