import React from 'react';
import Header from './../components/Header';
import Footer from './../components/Footer';
import Categories from './../components/Categories'
import CookieConsent from 'react-cookie-consent';

const HomepageLayout = props => {
  return (
  
    <div className="fullHeight">

      <CookieConsent
      
      style = {{background: '#FF7C00', fontSize: '14px'}}
      buttonStyle = {{
        background: '#FFFFFF',
        fontWeight: "200",
        color:'#FF7C00',
      
        }}
      buttonText = "Agree" >
      <span>  We use cookies to ensure that we give you the best experience on our website. By using our website, you agree to our 
      </span>
      <span style={{ fontSize: "15px", color: "#6E48A8"}}>  Privacy policy 
      </span>
        
      
     

  
      </CookieConsent>
      <Header {...props} />
  
      {props.children}
      
     
      <Footer />
    </div>
  




  );
};
export default HomepageLayout;