import React from 'react';
import Checkout from './../../components/Checkout';
import Footer from './../../components/Footer';


const Cart = ({}) => {
  return (
  <div>  
    <div>
      <Checkout />
    </div>
    <div>
      <Footer />
          
    </div>

  </div>
      
    
  );
}

export default Cart;