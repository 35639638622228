import React, { useState, useEffect } from "react";
import { apiInstance } from "../../Utils";
import './styles.scss';
import firebase from "firebase";
import { Grid , Button, TextField} from "@material-ui/core";
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";




var db = firebase.firestore()
export default function StripePay() {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');

  //address state 
  const [country, setCountry] = useState('')
  const [zip, setZip] = useState('')
  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [a2, setA2] = useState('')
  const [a1, setA1] = useState('')
  const [cart, setCart] = useState(' ')
  //personal info
  const [lastName, setLast] = useState('')
  const [firstName, setFirst] = useState('')
  const [externalUID, setExternalUID] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('') 
  //cart total
  const [subTotal, setSubTotal] = useState('')
  const [Taxes, setTaxes] = useState('')
  const [Total, setTotal] = useState('100')

    const stripe = useStripe();
    const elements = useElements();
    
   
  
  
  useEffect(() => {

    
    //get userr data
    firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          //console.log(user.uid)
          const UID = user.email
          const newUID = UID.slice(0, -4)
          setEmail(UID)
          setExternalUID(newUID)
          console.log(newUID)
          
          // ...
          // User is signed out
          // ...
          //get address reference
          //address document refence
          const aRef = db.collection('cart').doc(newUID)
                        .collection('address').doc('address1')
          //if exist
          aRef.get().then((doc) => {
              if (doc.exists) {
                  //set address info
                  setA1(doc.data().a1)
                  setA2(doc.data().a2)
                  setCity(doc.data().city)
                  setCountry(doc.data().country)
                  setState(doc.data().state)
                  setZip(doc.data().zip)
                  //segt personal info
                  setFirst(doc.data().firstName)
                  setLast(doc.data().lastName)
                   //console.log(doc.data().a1)
              } else {
                  // doc.data() will be undefined in this case
                  console.log("No such document!");
              }
          }).catch((error) => {
              console.log("Error getting document:", error);
          });

          //order total reference
         const tRef = db.collection('cart').doc(newUID)
                        .collection('total').doc('currentCar')
            //if exist
            tRef.get().then((doc) => {
              if (doc.exists) {
                  //set subtotal
                 setSubTotal(doc.data().subTotal)
                 setTaxes(doc.data().taxes)
                 setTotal(doc.data().total)
              } else {
                  // doc.data() will be undefined in this case
                  console.log("No such document!");
              }
          }).catch((error) => {
              console.log("Error getting document:", error);
          });


          //get cart reference
          const cartRef = db.collection('cart').doc(newUID)
                          .collection('items')
          // get cart
          var cartData = []
          
          cartRef.get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              if(doc.data().quantity >= 1){
                cartData  = cartData.concat(doc.data())
                setCart(cartData)
              }
      
                //if(doc.data().quantity <= 0){
                  //console.log(cartData)
                 // console.log(doc.data().name)
                  //console.log(doc.data().productID)
                //}else{
                  //console.log('no Data')
                   
                

                //}
               
                //console.log(doc.data());
            });
            //console.log(cart)
        });
      
        }
      });

    //
      
    // get address info
    // Create PaymentIntent as soon as the page loads
    //const valuePass = parseInt(Total*100)
    apiInstance.post("https://us-central1-lottm-aedc6.cloudfunctions.net/api/create-payment-intent", {
        amount: parseInt(Total*100),
        headers: {
          "Content-Type": "application/json"
        },
        //body: JSON.stringify([{amount: '100'}])
      })
      .then(data => {
        setClientSecret(data.data.clientSecret);
        //console.log(clientSecret)
      })

  }, []);
  
    const cardStyle = {
        style: {
        base: {
            color: "#32325d",
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
            color: "#32325d"
            }
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a"
        }
        }
    };
  const handleChange = async (event) => {
   
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };


const handleSubmit = async (event)  => {
  
    event.preventDefault();
    setProcessing(true);
    const cardElement = elements.getElement('card');

   await apiInstance.post('/create-payment-intent', {
      amount: parseInt(Total*100),
      shipping: {
        name: 'test',
      }
      
    })
    .then(({ data: clientSecret }) => {

      stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: (firstName + lastName),
        }

      }).then(({paymentMethod }) => {
          //console.log(paymentMethod)
         stripe.confirmCardPayment(clientSecret, {
           
              payment_method: {
                  card: cardElement,
                  billing_details: {
                    name: (firstName + '  ' + lastName)
                  },
              },
          }).then((data) => {
            if (data.error){
              console.log('not ok')
              setProcessing(false)
              setError(`Payment failed: ${data.error.message}, please try again.`);
              alert(data.error.message)
            }else{
              console.log('ok')
              setError(null);
              setProcessing(false)
              setSucceeded(true);

              //order ref
              //date created - 
              //email - ready
              //items - 
              //itemsID - 
              //nameFirst - ready
              //nameLast - ready
              //nameUser - ready
              //phone - 
              //shipping - ready
              //zstatus - ready
              //Ztotal - Ready
              //----------------
              //clean cart.

              var ORef = db.collection('orders').doc()
              //if exist
              ORef
                .set({
                  dateCreated: firebase.firestore.FieldValue.serverTimestamp(), 
                  email: email,
                  nameFirst: firstName,
                  nameLast: lastName,
                  Ea1: a1,
                  Ea2: a2,
                  Ecity: city,
                  Estate: state,
                  Ezip: zip,
                  Ecountry: country,
                  phone: phone,
                  nameUser: externalUID,
                  zStatus: 'processing',
                  zTotal: Total,
                  items: cart,
                  })
                  .then(() =>  {
                      console.log('Document Added ');
                      {window.location='/ordersSurf'}
                    
                  })
                  .catch((error) => {
                      console.log('Error adding document: ', error);
                  });   

              //{window.location='/orderspage'}
            }
            // if(error.message === 'undefined'){
            //   alert('cool')
            //   setProcessing(false);
            //   //return
            // }else{
            //   alert('not cool')
            //   setProcessing(false);
            // }

            })
            
            
          
        })
        
    });


      
  };

  

 

  return (
      
  <div className = 'card'> 
       
        <li>
  
        <li>
        <h1>shipping address </h1> 
         <h2>Address 1: {a1}</h2>  
         <h2>Address 2: {a2}</h2>  
         <h2>City: {city}</h2>  
         <h2>State: {state}</h2>  
         <h2>Country:  {country}</h2>  
         <h2>Zip: {zip}</h2>  
 
         <Button className = 'callToAction' onClick={() =>  {window.location='/shipping'}} >
            <p> Update </p>
         </Button>
      
        </li>
        </li>

        <li>
        <h1>Personal information</h1> 
         <h2>{firstName}</h2>  
         <h2>{lastName}</h2>  
         <h2>{email}</h2>  

         <Button className = 'callToAction' onClick={() =>{window.location='/shipping'}} >
            <p> Update </p>
         </Button>

        </li>

        
        <li>
         <h1>Order total</h1> 
         <h2>Subtotal: $ {subTotal}</h2>  
         <h2>Taxes: $ {Taxes}</h2>  
         <div className = 'separatortall'></div>
         <h2>Total: $ {Total} </h2>  

         <Button className = 'callToAction' onClick={() => {window.location='/cartpage'}} >
            <p> Go back to cart </p>
         </Button>
        
        </li>
    
    
        <div className ='separatorTall'></div>
    <form id="payment-form" onSubmit={handleSubmit}>
      <CardElement id="card-element" options={cardStyle} onChange= {handleChange} />
      <button className = 'callToAction'
        disabled={processing || disabled || succeeded}
        id="submit"
      >
        <p id="button-text">
          {processing ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            "PAY NOW"
          )}
        </p>
      </button>
{/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )}
      {/* Show a success message upon completion */}
      
    </form>
    
  </div>
   );
  
}