
import React from 'react';
import GetItem from '../../components/GetItem'
import NewProductCard from '../../components/SurfNoteProductCard'
import Footer from '../../components/Footer'
import Categories from '../../components/Categories'
import SurfNoteProductCard from '../../components/SurfNoteProductCard';

//mport FetchRandomUser from './../../components/GetItem';

const SurfSearchPage = ({}) => {
  return (
  
      <div>
        <SurfNoteProductCard />
       
      </div>
  


    
  );
}


  export default SurfSearchPage;