import React from "react";
import $, { ready } from "jquery";
import './styles.scss'
import { Grid , Button} from "@material-ui/core";
import { Link , useHistory} from 'react-router-dom'
import Header from "../Header";
import Footer from "../Footer";
import { homedir } from "os";
import firebase from "firebase";




var crypto = require('crypto');
const { v4: uuidv4 } = require('uuid');

const secret = 'haOoTBU2VDsaQy';
const myuuid = uuidv4();
const method = "cart_add_item"
const method2 = "cart_set_address"
//var myuuid = 'b93cee9d-dd04-4154-9b5a-8768971e72b8';
var d = new Date();
var date = d.toISOString()
//var d  = '2013-01-01T01:30:00Z';
//var test = "3456789";
const userID =  firebase.auth().currentUser

const hash =crypto.createHmac('sha1',  secret)
    .update(method + myuuid + date)
    .digest('base64')

const hashTest =crypto.createHmac('sha1',  secret)
    .update(method2 + myuuid + date)
    .digest('base64')

//console.log(d);
//console.log(d.toISOString());
//console.log('checksum is: ' cart_view + hash +   )
//console.log('your hash is: ' + hash  );
//console.log('your UUID is: ' + myuuid  );

export default class DetailedProduct extends React.Component {
  
  state = {
    loading: true,
    brand: null,
    image: null,
    name:  null,
    price:  null,
    SEstimate: null,
    details: null,
    item: null,
    category: 0,
    catalogId: this.props.match.params.productId,
    externalUID : null
    
  };

  getAccount(){
     firebase.auth().onAuthStateChanged((user) => {
            if (user) {
              // User is signed in, see docs for a list of available properties
              // https://firebase.google.com/docs/reference/js/firebase.User
              //console.log(user.uid)
              const UID = user.email
              const newUID = UID.slice(0, -4)
              this.setState({externalUID: newUID })
              console.log(this.state.externalUID)
              // ...
              // User is signed out
              // ...
            }
          });
  }

  addToCart(){
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.viewCart()
      }
      else{
        window.location='/login'
      }
    });

  }

  async viewCart(){

    
    
    const url = "http://playpay.dev.catalogapi.com/v1/jsonp/cart_add_item/?creds_checksum="+ encodeURIComponent(hash) +'&creds_datetime=' +  encodeURIComponent(date) + '&creds_uuid=' + encodeURIComponent(myuuid) + '&external_user_id=' + this.state.externalUID + '&socket_id=1272' +  '&catalog_item_id=' + this.state.catalogId+ '&quantity=1'
    const response =  await fetch(url,{
      mode: 'no-cors',
    })
    
    const data = await response;
    console.log(data)
    window.location='/cartpage'
    

  }
  async viewCart2(){
    const url = "http://playpay.dev.catalogapi.com/v1/rest/cart_view/?creds_checksum="+ encodeURIComponent(hash) +'&creds_datetime=' +  encodeURIComponent(date) + '&creds_uuid=' + encodeURIComponent(myuuid) + '&external_user_id=1&socket_id=1272&quantity=1&catalog_item_id=' + this.state.catalogId
    const response =  await $.getJSON(url,)
    const data = await response;
    console.log(data)

  }

   async postToCart(){
     
    const url = "http://playpay.dev.catalogapi.com/v1/rest/cart_add_item/"
    const response =  await $.getJSON(url + '?creds_checksum=' + hash +'&creds_datetime=' +  date + '&creds_uuid=' + myuuid ,
            {
           // dataType: 'text'
            quantity: 1,
            external_user_id: "123",
            catalog_item_id: this.state.catalogId,
            //token: "b6ab2dc359b7b68ebb37762bac12395c10b9862c",
            socket_id: '1272',
            //creds_checksum: this.hash,
            //creds_uuid : this.myuuid,
            //creds_datetime :  this.d
            //crossDomain : true,
            },)
            const data = await response;
            console.log(data)
            

  }

   async fetchDataItem(){
    const url = "http://playpay.dev.catalogapi.com/v1/rest/view_item/";
    const response = await $.getJSON(url,  
            {
            catalog_item_id: this.state.catalogId,
            token: "b6ab2dc359b7b68ebb37762bac12395c10b9862c",
            socket_id: '1272',
            },)
    const data = await response;
    //console.log(data)//.catalog_breakdown_response.catalog_breakdown_result.categories.Category)
    //this.setState({result: data.catalog_breakdown_response.catalog_breakdown_result.categories.Category, loading:false })
    this.setState({item: data.view_item_response.view_item_result.item})
    this.setState({image:  data.view_item_response.view_item_result.item.image_300})
    this.setState({name:  data.view_item_response.view_item_result.item.name})
    this.setState({price:  data.view_item_response.view_item_result.item.catalog_price})
    this.setState({brand:  data.view_item_response.view_item_result.item.brand})
    this.setState({SEstimate:  data.view_item_response.view_item_result.item.shipping_estimate})

    this.setState({details: data.view_item_response.view_item_result.item.description})

    console.log(data.view_item_response.view_item_result.item)
  }

  parseDetails(newDetails){
  
    return
  }
      
  

  async componentDidMount() {    


  
      this.getAccount()
      this.fetchDataItem()
      
       
    }
    render() {
      
      
        return (
          <div>
            <Header/>
            <Grid container className= 'container'>
              <Grid item className = 'column1'>
                  <div className="hero">
                    <img src= {this.state.image} />    
                    </div>
              </Grid>
              <Grid item  className = 'column2'>
                  <div className = 'productCard'>
                    <ul>
                      <li>
                      <h1>
                        </h1>
                        <h1>
                          {this.state.name}
                        </h1>
                      </li>
                      <li>
                        <span className = 'price'>
                          ${this.state.price}
                        </span>
                      </li>
                      <li>
                        <span className = 'shipping'>
                        Shipping: ${this.state.SEstimate}
                        </span>
                      </li>
                      <li>
                        <span  className = 'brand'>
                        Brand: {this.state.brand}
                        </span>
                      </li>
                    </ul>
                    <div >
                      <Button className="addToCart" onClick={() => {this.addToCart()}}>
                        <p>
                          Add to cart
                        </p>
                      </Button>

                
                    </div>
                    <div>
                    <Link className="viewPolicy" to = "/TermsOfUse">
                          <p>
                            view return policy
                          </p>
                      </Link>
                    </div>
                </div>
              </Grid>
                
                  <div  dangerouslySetInnerHTML={{ __html: this.state.details }} className = "description">
                    
                  </div>
              
            </Grid> 
            <Footer/> 
          </div>  
      
           
        );
      }

}
   
//className = "description"
// {this.state.details}
